import Index from "./views/Index.js";
import Profile from "./views/examples/Profile.js";
import Register from "./views/examples/Register.js";
import Login from "./views/examples/Login.js";
import Tables from "./views/examples/Tables.js";
import Icons from "./views/examples/Icons.js";
import Services from "./views/examples/Services.js";
import Products from "./views/examples/Products.js";
import About from "./views/examples/About.js";
import Banner from "./views/examples/Banner.js";
import Award from "./views/examples/Award.js";
import Testimonial from "./views/examples/Testimonial.js";
import News from "./views/examples/News.js";
import CaseStudies from "./views/examples/CaseStudies.js";
import Blog from "./views/examples/Blog.js"
import Contact from "./views/examples/Contact.js"
import Leadership from "./views/examples/Leadership.js"
import Clients from "./views/examples/Clients.js"
import OfficeLocations from "./views/examples/OfficeLocations.js"
import Career from "./views/examples/Career.js"
import Milestones from "./views/examples/Milestones.js";
import SuccessStories from "./views/examples/SuccessStories.js";
import JobDetails from "./views/examples/JobDetails.js";
import LifeatFss from "./views/examples/LifeatFss.js";

var routes = [
  // {
  //   path: "/index",
  //   name: "Dashboard",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: <Index />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: <Profile />,
  //   layout: "/admin",
  // },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: <Register />,
    layout: "/auth",
  },
  {
    path: "/services",
    name: "Services",
    icon: "ni ni-circle-08 text-pink",
    component: <Services />,
    layout: "/admin",
  },
  
  // {
  //   path: "/products",
  //   name: "Products",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: <Products />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/about",
  //   name: "About",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: <About />,
  //   layout: "/admin",
  // },
  {
    path: "/award",
    name: "Award",
    icon: "ni ni-circle-08 text-pink",
    component: <Award />,
    layout: "/admin",
  },
  {
    path: "/testimonial",
    name: "Testimonial",
    icon: "ni ni-circle-08 text-pink",
    component: <Testimonial />,
    layout: "/admin",
  },
  {
    path: "/successstories",
    name: "Success Stories",
    icon: "ni ni-circle-08 text-pink",
    component: <SuccessStories />,
    layout: "/admin",
  },
  {
    path: "/lifeatfss",
    name: "LifeAt FSS",
    icon: "ni ni-circle-08 text-pink",
    component: <LifeatFss />,
    layout: "/admin",
  },
  // {
  //   path: "/jobdetails",
  //   name: "Job Details",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: <JobDetails />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/news",
  //   name: "News",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: <News />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/casestudies",
  //   name: "Case Studies",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: <CaseStudies />,
  //   layout: "/admin",
  // },
  {
    path: "/blog",
    name: "Blog",
    icon: "ni ni-circle-08 text-pink",
    component: <Blog />,
    layout: "/admin",
  },
  {
    path: "/Milestones",
    name: "Milestones",
    icon: "ni ni-circle-08 text-pink",
    component: <Milestones />,
    layout: "/admin",
  },
  // {
  //   path: "/contact",
  //   name: "Contact",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: <Contact />,
  //   layout: "/admin",
  // },
  {
    path: "/leadership",
    name: "Leadership",
    icon: "ni ni-circle-08 text-pink",
    component: <Leadership />,
    layout: "/admin",
  },
  {
    path: "/clients",
    name: "Clients",
    icon: "ni ni-circle-08 text-pink",
    component: <Clients />,
    layout: "/admin",
  },
  // {
  //   path: "/OfficeLocations",
  //   name: "OfficeLocations",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: <OfficeLocations />,
  //   layout: "/admin",
  // },
  {
    path: "/career",
    name: "Career",
    icon: "ni ni-circle-08 text-pink",
    component: <Career />,
    layout: "/admin",
  },
];
export default routes;
