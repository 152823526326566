import { useState, useEffect } from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Editor } from "primereact/editor";
import MyS3Component from "../../components/s3/s3";
import Swal from "sweetalert2";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    CustomInput,
    UncontrolledTooltip,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Input,
    Table, Alert,
    Label,
    FormText,
    FormFeedback
} from "reactstrap";
import ReactPaginate from 'react-js-pagination';
import axiosInstance from '../../axiosInterceptor';
// core components
// import Header from "components/Headers/Header.js";
//import Table from "../../components/Table/Table";

const apiResponseToColumnsMapping = {
    'image_title': 'Story Name',
    'serviceSubject': 'Subject',
    'serviceStatus': 'Status',
    'link': 'Link'
};

const columns = ['Story Title', 'Story Image', 'Banner Image', 'Story Description', 'Action'];
const column = ['ss_image_title', 'ss_image', 'ss_banner_img', 'ss_image_description', 'action'];

const initialFormData = {
    ss_success_stories_list: [{
        ss_story_title: '',
        ss_story_description: ''
    }]
}

const SuccessStories = () => {
    const itemsPerPage = 5; // Number of items to display per page
    const [modal, setModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    console.log("selectedRow data", selectedRow);
    const [tableData, setTableData] = useState([]);
    const [stateformData, setFormData] = useState(initialFormData);
    console.log("formData is", stateformData);
    const [successStoriesResData, SetSuccessStoriesResData] = useState([]);
    const [isImagesVisible, setIsImagesVisible] = useState(true);
    console.log("successStoriesResData", successStoriesResData)
    const [isClicked, setIsClicked] = useState(false);
    const [isValidate, setIsValidate] = useState({})
    const [isImageVisible, setIsImageVisible] = useState(true);
    const [updatedImageUrl,setUpdatedImageUrl]=useState({})
    // const [selectedSucessStoriesData, setSelectedSucessStoriesData] = useState({})

    const toggle = () => setModal(!modal);
    const toggleSuccessModal = () => setSuccessModal(!successModal);
    useEffect(() => {
        sucessstoriesGetApi()
    }, []);
    const token = localStorage.getItem('JWT');
    const sucessstoriesGetApi = () => axiosInstance.get("/successStories/getsuccessStories").then(response => {
        console.log("services response DATA", response.data);
        SetSuccessStoriesResData(response.data.data);
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = response.data?.data?.slice(indexOfFirstItem, indexOfLastItem);
        setTableData(currentItems);
        // setResponseData(r)

    }).catch(error => {
        console.log("Error from API", error);
    });
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        const indexOfLastItem = pageNumber * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = successStoriesResData?.slice(indexOfFirstItem, indexOfLastItem);
        setTableData(currentItems);
    };
    const handleRemovedImage = () => {
        // Handle the logic to remove the image
        setIsImagesVisible(false);
    };
    const handleRemoveImage = () => {
        
        setIsImageVisible(false);
    };
    console.log("data", successStoriesResData, columns);
    const modalHeaderTag = 'h2';
    const openEditModal = async (row) => {
        setSelectedRow(row);
        setEditModalOpen(true);

        // Fetch service data by code
        // try {
        //     const response = await axiosInstance.get(`/successStories/getsuccessStories?ss_successstories_code=${row.ss_successstories_code}`);
        //     setSelectedSucessStoriesData(response.data.data[0]);
        // } catch (error) {
        //     console.log("Error fetching service data by code:", error);
        // }
    };

    const openDeleteModal = (row) => {
        setSelectedRow(row);
        setDeleteModalOpen(true);
    };
    const closeEditModal = () => {
        setEditModalOpen(false);
        setSelectedRow({});
    };
    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
        setSelectedRow({});
    };

    const handleCreate = async () => {
        setIsClicked(true);
        const validationErrors = {};
        if (stateformData.ss_image_title?.length < 5) {
            validationErrors.ss_image_title = "Input must be at least 5 characters long";
        }
        if (!stateformData.ss_image_title) {
            validationErrors.ss_image_title = 'Please fill this field';
        }
        if (stateformData.ss_image_description?.length < 5) {
            validationErrors.ss_image_description = "Input must be at least 5 characters long";
        }
        if (!stateformData.ss_image_description) {
            validationErrors.ss_image_description = 'Please fill this field';
        }
        if (stateformData.ss_banner_img_title?.length < 5) {
            validationErrors.ss_banner_img_title = "Input must be at least 5 characters long";
        }
        if (!stateformData.ss_banner_img_title) {
            validationErrors.ss_banner_img_title = 'Please fill this field';
        }
        if (stateformData.ss_link?.length < 5) {
            validationErrors.ss_link = "Input must be at least 5 characters long";
        }
        if (!stateformData.ss_link) {
            validationErrors.ss_link = 'Please fill this field';
        }
        if (!stateformData.ss_banner_img) {
            validationErrors.ss_banner_img = 'Please fill this field';
        }
        if (!stateformData.ss_image) {
            validationErrors.ss_image = 'Please fill this field';
        }
        setIsValidate(validationErrors);
        if (Object.keys(validationErrors).length > 0) {
            return;
        }
        const config = {
            headers: {
                "content-type": "multipart/form-data",
                "x-auth-token": token
            }
        };
        const api = "successStories/successStoriesCreation";
        try {
            const response = await axiosInstance.post(api, stateformData, config);
            console.log("successStories creation response DATA", response.data);
            sucessstoriesGetApi();
            toggle();
            setFormData(initialFormData);
            // Swal.fire({
            //     icon: 'success',
            //     title: 'Success',
            //     text: 'successStories added successfully!',
            //     confirmButtonColor: '#3085d6',
            //     confirmButtonText: 'OK'
            // });
        } catch (error) {
            console.log("successStories creation Error from API", error);
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Error',
            //     text: 'successStories is not created.',
            //     confirmButtonColor: '#d33',
            //     confirmButtonText: 'OK'
            // });
        };
    };
    const handleEdit = async () => {
        setIsClicked(true);
        const validationErrors = {};

        const validationRules = {
            ss_image_title: { required: true, minLength: 5 },
            ss_image_description: { required: true, minLength: 5 },
            ss_banner_img_title: { required: true, minLength: 5 },
            ss_link: { required: true, minLength: 5 },
            ss_banner_img: { required: true},
            ss_image: { required: true }
        };
        
        for (const [fieldName, rules] of Object.entries(validationRules)) {
            const value = stateformData[fieldName] || selectedRow[fieldName];
            if (rules.required && (!value || value === "")) {
                validationErrors[fieldName] = 'Please fill this field';
            } else if (rules.minLength && value && value.length < rules.minLength) {
                validationErrors[fieldName] = `Please fill this field atleast ${rules.minLength} characters long`;
            }
        }

        setIsValidate(validationErrors);

        // Check if there are any validation errors
        if (Object.keys(validationErrors).length > 0) {
            return; // Stop further execution if there are validation errors
        }
        const config = {
            headers: {
                "content-type": "multipart/form-data",
                "x-auth-token": token
            }
        };
        
        const api = "successStories/successStoriesEdit";

        console.log("req data to edit api", selectedRow);
        try {
            const response = await axiosInstance.put(api, selectedRow, config);

            console.log("Edit service response:", response);
            const updatedImageUrl = response.data.image_url;
            setUpdatedImageUrl(updatedImageUrl);
            setSelectedRow({})
            sucessstoriesGetApi();
            closeEditModal();
            // Swal.fire({
            //     icon: 'success',
            //     title: 'Success',
            //     text: 'Success Stories Updated successfully!',
            //     confirmButtonColor: '#3085d6',
            //     confirmButtonText: 'OK'
            // });
        } catch (error) {
            console.error("Error updating service:", error);
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Error',
            //     text: 'Failed to update Success Stories',
            //     confirmButtonColor: '#d33',
            //     confirmButtonText: 'OK'
            // });
        }
    };

    const handleDelete = async () => {
        try {
            const response = await axiosInstance.delete(`/successStories/deletesuccessStories?ss_successstories_code=${selectedRow.ss_successstories_code}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'accept': 'application/json',
                        'x-auth-token': token
                    }
                });
            console.log("Delete service response:", response.data);
            sucessstoriesGetApi();
            closeDeleteModal();
            // Swal.fire({
            //     icon: 'success',
            //     title: 'Success',
            //     text: 'SucessStories deleted successfully!',
            //     confirmButtonColor: '#3085d6',
            //     confirmButtonText: 'OK'
            // });
        } catch (error) {
            console.log("Error deleting service:", error);
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Error',
            //     text: 'SucessStories is not Deleted',
            //     confirmButtonColor: '#d33',
            //     confirmButtonText: 'OK'
            // });
        }
    };

    const handleAddNewStoryPoint = () => {
        setFormData((prevData) => {
            console.log("prevData is", prevData);
            const newStory = {
                ss_story_title: '',
                ss_story_description: ''
            };
            return {
                ...prevData,
                ss_success_stories_list: [...prevData?.ss_success_stories_list, newStory]
            };
        });
    };
    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    
        if (file && !allowedTypes.includes(file.type)) {
            alert('Please select a valid image file (PNG or JPG).');
            e.target.value = '';
        } else {
            handleInputChange(e.target.name, file);
        }
    };
    const handleEditNewStoryPoint = () => {
        setSelectedRow((prevData) => {
            console.log("prevData in edit is", prevData);
            const newStory = {
                ss_story_title: '',
                ss_story_description: ''
            };
            return {
                ...prevData,
                ss_success_stories_list: [...prevData?.ss_success_stories_list, newStory]
            };
        });
    };

    const handleStoryInputChange = (index, field, value) => {
        console.log("index ==========", index, field, value);

        setFormData((prevData) => {
            console.log("prevData state data is", prevData);
            const updatedStories = prevData.ss_success_stories_list.map((story, idx) => {
                console.log("STORY DATA", story);
                if (String(idx) === String(index)) {
                    console.log("       return ing data", { ...story, [field]: value });
                    return { ...story, [field]: value };
                }
                return story;
            });
            console.log("updated story", updatedStories);
            return {
                ...prevData,
                ss_success_stories_list: updatedStories,
            }
        });
    };
    const handleEditStoryInputChange = (index, field, value) => {
        console.log("index ==========", index, field, value);

        setSelectedRow((prevData) => {
            console.log("prevData state data is", prevData);
            const updatedStories = prevData.ss_success_stories_list.map((story, idx) => {
                console.log("STORY DATA", story);
                if (String(idx) === String(index)) {
                    console.log("       return ing data", { ...story, [field]: value });
                    return { ...story, [field]: value };
                }
                return story;
            });
            console.log("updated story", updatedStories);
            return {
                ...prevData,
                ss_success_stories_list: updatedStories,
            }
        });
    };
    const handleRemoveStory = (index) => {
        const updatedStories = [...stateformData.ss_success_stories_list];
        updatedStories.splice(index, 1);
        setFormData({ ...stateformData, ss_success_stories_list: updatedStories });
    };
    const handleEditRemoveStory = (index) => {
        const updatedStories = [...selectedRow.ss_success_stories_list];
        updatedStories.splice(index, 1);
        setSelectedRow({ ...selectedRow, ss_success_stories_list: updatedStories });
    };

    const handleInputChange = (inputName, val) => {
        console.log("Event data change", inputName, "   val", val);
        if(inputName ==='ss_banner_img'||inputName==='ss_image'){
            const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            if (val && !allowedTypes.includes(val.type)) {
                alert('Please select a valid image file (PNG or JPG).');
            }
            setFormData((prevData) => {
                return {
                    ...prevData,
                    [inputName]: val,
                }
            })
            setIsValidate((prevValidation) => ({
                ...prevValidation,
                [inputName]: !!val,
            }));

        }
        setFormData((prevData) => {
            return {
                ...prevData,
                [inputName]: val,
            }
        })
        setIsValidate((prevValidation) => ({
            ...prevValidation,
            [inputName]: !!val, // Update validation status based on whether the field has a value
        }));
    };
    const handleEditInputChange = (inputName, val) => {
        console.log("Event data change", inputName, "   val", val);
        setSelectedRow((prevData) => {
            return {
                ...prevData,
                [inputName]: val,
            }
        })
        setIsValidate((prevValidation) => ({
            ...prevValidation,
            [inputName]: !!val,
        }));
    };

    return (
        <>
            {/* <Header /> */}
            {/* Page content */}
            <Container className="mt-5" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="bg-transparent d-flex justify-content-between">
                                <h2 className="mb-0">Success Stories</h2>
                                <Button color="primary" onClick={toggle}>Add</Button>
                            </CardHeader>
                            <CardBody>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            {columns.map((column, index) => (
                                                <th key={index}>{column}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.map((row, rowIndex) => (
                                            <tr key={rowIndex}>
                                                {column.map((column, columnIndex) => (
                                                    <td key={columnIndex}>
                                                        {column === 'action' ? (
                                                            <>
                                                                <button className="btn btn-primary" onClick={() => openEditModal(row)}>
                                                                    edit
                                                                </button>
                                                                <button className="btn btn-default" onClick={() => openDeleteModal(row)}>
                                                                    delete
                                                                </button>
                                                            </>
                                                        ) :
                                                            column === 'ss_image' ? (
                                                                // <MyS3Component imageURL={row['image_url']} style={{ display: 'block', width: 60, height: 40 }} />
                                                                <MyS3Component key={row['ss_successstories_code']} imageUrl={row['ss_image']} />
                                                            ) :
                                                                column === 'ss_banner_img' ? (
                                                                    // <MyS3Component imageURL={row['image_url']} style={{ display: 'block', width: 60, height: 40 }} />
                                                                    <MyS3Component key={row['ss_successstories_code']} imageUrl={row['ss_banner_img']} />
                                                                ) :
                                                                    column === "ss_status" ? (
                                                                        <>
                                                                            <CustomInput type="switch" id={`customSwitch${rowIndex}`} checked={row['ss_status'] === 'ACTIVE'} />
                                                                        </>
                                                                    ) :
                                                                        (
                                                                            row[column]
                                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>

                                </Table>
                                {
                                    successStoriesResData.length > itemsPerPage && (
                                        <ReactPaginate
                                            activePage={currentPage}
                                            itemsCountPerPage={itemsPerPage}
                                            totalItemsCount={successStoriesResData.length}
                                            pageRangeDisplayed={5}
                                            onChange={handlePageChange}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            innerClass="pagination justify-content-center"
                                        />
                                    )
                                }
                            </CardBody>
                        </Card>
                    </div>
                </Row>
                <Modal isOpen={modal} toggle={toggle} centered size="lg">
                    <ModalHeader toggle={toggle} tag={modalHeaderTag}>Add Stories</ModalHeader>
                    <ModalBody className="bg-secondary">
                        <Row>
                            <Col lg="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Name"
                                    >
                                        Success Story Title
                                    </label>
                                    <Input
                                     className={`form-control-alternative ${
                                        isValidate.ss_image_title ? (isValidate.ss_image_title?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                    }`}
                                        type="text"
                                        name="ss_image_title"
                                        // value={formData.ss_image_title}
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, ss_image_title: true })}
                                        required
                                        aria-describedby="name-error"
                                    />
                                     {isClicked && isValidate.ss_image_title && isValidate.ss_image_title?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.ss_image_title}</small>
                                )}
                                </FormGroup>
                            </Col>

                            <Col lg="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Branch"
                                    >
                                        Success Story Description:
                                    </label>
                                    <textarea
                                         className={`form-control-alternative ${
                                            isValidate.ss_image_description ? (isValidate.ss_image_description?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        defaultValue=""
                                        id="Branch"
                                        rows={6}
                                        style={{ width: "100%" }}

                                        placeholder="Please Enter description"
                                        value={stateformData.ss_image_description}
                                        name="ss_image_description"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, ss_image_description: true })}
                                        required
                                        aria-describedby="name-error"
                                    />
                                     {isClicked && isValidate.ss_image_description && isValidate.ss_image_description?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.ss_image_description}</small>
                                )}
                                </FormGroup>
                            </Col>


                            <Col lg="6">
                                <FormGroup>
                                    <Label for="serviceimage">Sucess story Image</Label>
                                    <Input type="file" id="serviceimage" name="ss_image" onChange={(e) => handleInputChange(e.target.name, e.target.files[0])}
                                        accept=".png, .jpg, .jpeg" onBlur={() => setIsValidate({ ...isValidate, ss_image: true })}
                                        required
                                        aria-describedby="name-error" />
                                    <FormText color="muted">
                                    Please select .png or .jpg images only within 1024x768px.
                                    </FormText>
                                    {isClicked && isValidate.ss_image && isValidate.ss_image?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.ss_image}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <Label for="serviceimage">Banner Image</Label>
                                    <Input type="file" id="serviceimage" name="ss_banner_img" onChange={(e) => handleInputChange(e.target.name, e.target.files[0])}
                                        accept=".png, .jpg, .jpeg" onBlur={() => setIsValidate({ ...isValidate, ss_banner_img: true })}
                                        required
                                        aria-describedby="name-error" />
                                    <FormText color="muted">
                                    Please select .png or .jpg images only within 1024x768px.
                                    </FormText>
                                    {isClicked && isValidate.ss_banner_img && isValidate.ss_banner_img?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.ss_banner_img}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Branch"
                                    >
                                        Banner Image Title
                                    </label>
                                    <Input
                                         className={`form-control-alternative ${
                                            isValidate.ss_banner_img_title ? (isValidate.ss_banner_img_title?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        defaultValue=""
                                        id="Branch"
                                        placeholder="Please Enter title"
                                        //value={formData.ss_image_title}
                                        type="text"
                                        name="ss_banner_img_title"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, ss_banner_img_title: true })}
                                        required
                                        aria-describedby="name-error"
                                    />
                                      {isClicked && isValidate.ss_banner_img_title && isValidate.ss_banner_img_title?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.ss_banner_img_title}</small>
                                )}
                                </FormGroup>
                            </Col>

                            <Col lg="12">
                                <FormGroup>
                                    <label>Button Link:</label>
                                    <Input
                                     className={`form-control-alternative ${
                                        isValidate.ss_link ? (isValidate.ss_link?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                    }`}
                                        type="text"
                                        name="ss_link"
                                        value={stateformData.ss_link}
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, ss_link: true })}
                                        required
                                        aria-describedby="name-error"
                                    />
                                     {isClicked && isValidate.ss_link && isValidate.ss_link?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.ss_link}</small>
                                )}
                                </FormGroup>

                            </Col>


                        </Row>

                        <Col lg="12">
                            {stateformData.ss_success_stories_list?.map((story, index) => (
                                <div key={index} style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px', position: 'relative' }}>
                                    <Button size="sm" style={{ position: 'absolute', top: '5px', right: '5px' }} onClick={() => handleRemoveStory(index)}>
                                        X
                                    </Button>
                                    <FormGroup>
                                        <label>Story Title:</label>
                                        <Input
                                            type="text"
                                            name="ss_story_title"
                                            value={story.ss_story_title}
                                            onChange={(e) => handleStoryInputChange(index, 'ss_story_title', e.target.value)}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label>Story Description:</label>
                                        <Editor key={index} onTextChange={(e) => handleStoryInputChange(index, 'ss_story_description', e.htmlValue)} style={{ height: '100px' }} name="ss_story_description" value={story.ss_story_description} />
                                    </FormGroup>
                                </div>
                            ))}
                        </Col>
                        <Button style={{ float: "right" }} color="info" onClick={handleAddNewStoryPoint}>
                            + Add Story
                        </Button>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleCreate}>
                            Add
                        </Button>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* Success Modal */}
                <Modal isOpen={successModal} toggle={toggleSuccessModal} centered>
                    <ModalBody>
                        <Alert color="success">Success Stories added successfully!</Alert>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={toggleSuccessModal}>
                            OK
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* Edit Modal */}
                <Modal isOpen={isEditModalOpen} toggle={closeEditModal} centered size="lg">
                    <ModalHeader toggle={closeEditModal} tag={modalHeaderTag}>Edit Row</ModalHeader>
                    <ModalBody className="bg-secondary">
                        <Row>
                            <Col lg="12">
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="Name">
                                        Success Story Title
                                    </label>
                                    <Input
                                     className={`form-control-alternative ${
                                        isValidate.ss_image_title ? (isValidate.ss_image_title?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                    }`}
                                        type="text"
                                        name="ss_image_title"
                                        onChange={(e) => handleEditInputChange(e.target.name, e.target.value)}
                                        defaultValue={selectedRow.ss_image_title || ""}
                                    />
                                      {isClicked && isValidate.ss_image_title && isValidate.ss_image_title?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.ss_image_title}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Branch"
                                    >
                                        Success Story Description:
                                    </label>
                                    <textarea
                                         className={`form-control-alternative ${
                                            isValidate.ss_image_description ? (isValidate.ss_image_description?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        id="Branch"
                                        rows={6}
                                        style={{ width: "100%" }}
                                        placeholder="Please Enter description"
                                        name="ss_image_description"
                                        onChange={(e) => handleEditInputChange(e.target.name, e.target.value)}
                                        // defaultValue={selectedSucessStoriesData.ss_image_description || ""}
                                        defaultValue={selectedRow.ss_image_description || ""}
                                    />
                                      {isClicked && isValidate.ss_image_description && isValidate.ss_image_description?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.ss_image_description}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <Label for="serviceimage">Sucess story Image</Label>
                                    <Input type="file" id="serviceimage" name="ss_image" onChange={(e) => handleInputChange(e.target.name, e.target.files[0])}
                                        accept=".png, .jpg, .jpeg" onBlur={() => setIsValidate({ ...isValidate, ss_image: true })}
                                        required
                                        aria-describedby="name-error" />
                                    <FormText color="muted">
                                    Please select .png or .jpg images only within 1024x768px.
                                    </FormText>
                                    {isImageVisible && (updatedImageUrl || selectedRow.ss_image) ? (
                                        <div style={{ position: 'relative', display: 'inline-block' }}>
                                            <MyS3Component key={selectedRow?.ss_successstories_code} imageUrl={selectedRow?.ss_image ? selectedRow?.ss_image : updatedImageUrl} />
                                            <Button size="sm" style={{ position: 'absolute', top: 0, right: "-40px" }} onClick={handleRemoveImage}>
                                                X
                                            </Button>
                                        </div>
                                    ) : null}
                                      {isClicked && isValidate.ss_image && isValidate.ss_image?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.ss_image}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <Label for="serviceimage">Banner Image</Label>
                                    <Input type="file" id="serviceimage" name="ss_banner_img" onChange={(e) => handleInputChange(e.target.name, e.target.files[0])}
                                        accept=".png, .jpg, .jpeg" onBlur={() => setIsValidate({ ...isValidate, ss_banner_img: true })}
                                        required
                                        aria-describedby="name-error" />
                                    <FormText color="muted">
                                    Please select .png or .jpg images only within 1024x768px.
                                    </FormText>
                                    {isImagesVisible && (updatedImageUrl || selectedRow.ss_banner_img) ? (
                                        <div style={{ position: 'relative', display: 'inline-block' }}>
                                            <MyS3Component key={selectedRow?.ss_successstories_code} imageUrl={selectedRow?.ss_banner_img ? selectedRow?.ss_banner_img : updatedImageUrl} />
                                            <Button size="sm" style={{ position: 'absolute', top: 0, right: "-40px" }} onClick={handleRemovedImage}>
                                                X
                                            </Button>
                                        </div>
                                    ) : null}
                                      {isClicked && isValidate.ss_banner_img && isValidate.ss_banner_img?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.ss_banner_img}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Branch"
                                    >
                                        Banner Image Title
                                    </label>
                                    <Input
                                         className={`form-control-alternative ${
                                            isValidate.ss_banner_img_title ? (isValidate.ss_banner_img_title?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        id="Branch"
                                        placeholder="Please Enter title"
                                        type="text"
                                        name="ss_banner_img_title"
                                        onChange={(e) => handleEditInputChange(e.target.name, e.target.value)}
                                        // defaultValue={selectedSucessStoriesData.ss_banner_img_title || ""}
                                        defaultValue={selectedRow.ss_banner_img_title || ""}
                                    />
                                      {isClicked && isValidate.ss_banner_img_title && isValidate.ss_banner_img_title?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.ss_banner_img_title}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="12">
                                <FormGroup>
                                    <label>Button Link:</label>
                                    <Input
                                     className={`form-control-alternative ${
                                        isValidate.ss_link ? (isValidate.ss_link?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                    }`}
                                        type="text"
                                        name="ss_link"
                                        onChange={(e) => handleEditInputChange(e.target.name, e.target.value)}
                                        // defaultValue={selectedSucessStoriesData.ss_link || ""}
                                        defaultValue={selectedRow.ss_link || ""}
                                    />
                                      {isClicked && isValidate.ss_link && isValidate.ss_link?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.ss_link}</small>
                                )}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Col lg="12">
                            {selectedRow.ss_success_stories_list?.map((story, index) => (
                                <div key={index} style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px', position: 'relative' }}>
                                    <Button size="sm" style={{ position: 'absolute', top: '5px', right: '5px' }} onClick={() => handleEditRemoveStory(index)}>
                                        X
                                    </Button>
                                    <FormGroup>
                                        <label>Story Title:</label>
                                        <Input
                                            type="text"
                                            name="ss_story_title"
                                            value={story.ss_story_title || ""}
                                            onChange={(e) => handleEditStoryInputChange(index, 'ss_story_title', e.target.value)}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label>Story Description:</label>
                                        <Editor
                                            key={index}
                                            onTextChange={(e) => handleEditStoryInputChange(index, 'ss_story_description', e.htmlValue)}
                                            style={{ height: '100px' }}
                                            name="ss_story_description"
                                            value={story.ss_story_description || ""}
                                        />
                                    </FormGroup>
                                </div>
                            ))}
                        </Col>
                        <Button style={{ float: "right" }} color="info" onClick={handleEditNewStoryPoint}>
                            + Add Story
                        </Button>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleEdit}>
                            Save
                        </Button>{" "}
                        <Button color="secondary" onClick={closeEditModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* Delete Modal */}
                <Modal isOpen={isDeleteModalOpen} toggle={closeDeleteModal} centered size="lg">
                    <ModalHeader toggle={closeDeleteModal} tag={modalHeaderTag}>Delete</ModalHeader>
                    <ModalBody className="bg-secondary">
                        Are you sure you want to delete this row?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={handleDelete}>
                            Yes
                        </Button>{" "}
                        <Button color="secondary" onClick={closeDeleteModal}>
                            No
                        </Button>
                    </ModalFooter>
                </Modal>
            </Container>
        </>
    );
};

export default SuccessStories;