
import { useEffect, useState } from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    CustomInput,
    UncontrolledTooltip,
    Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Table,Alert,FormText
} from "reactstrap";
import ReactPaginate from 'react-js-pagination';
import axiosInstance from "../../axiosInterceptor";
import Swal from "sweetalert2";
// core components
// import Header from "components/Headers/Header.js";
//import Table from "../../components/Table/Table";

const data = [
    { id: 1, icons: 'John Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 1', action: 'edit,delete', status: '' },
    { id: 2, icons: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 2', action: 'edit,delete', status: 'inactive' },
    { id: 3, icons: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 3', action: 'edit,delete', status: 'active' },
    { id: 4, icons: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 4', action: 'edit,delete', status: 'inactive' },
    { id: 5, icons: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 5', action: 'edit,delete', status: 'active' },
    // Add more data as needed
];


const columns = [ 'COMPANY NAME', 'COUNT OF MILESTONES',  'ACTION'];
const Milestones = () => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const itemsPerPage = 5; // Number of items to display per page
    const [currentPage, setCurrentPage] = useState(1);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [successModal, setSuccessModal] = useState(false);
    const [MilestonesData,SetMilestonesData]=useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [stateFormData,setFormData]=useState(null);
    const toggleSuccessModal = () => setSuccessModal(!successModal);
    const [isClicked, setIsClicked] = useState(false);
    const [isValidate, setIsValidate] = useState({ cm_name: false, cm_count: false})
    const[selectedMilestonesData,setselectedMilestonesData]=useState({});
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    useEffect(() =>{
        MilestonesGetApi()
        console.log("MilestonesData",Milestones)
    },[]);
    const token = localStorage.getItem('JWT');
    const MilestonesGetApi= () => axiosInstance.get("/CM/cmFetch").then(response => {
        console.log("Milestones response DATA", response.data);
        SetMilestonesData(response.data);

    }).catch(error => {
        console.log("Error from API", error);
    })

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = (MilestonesData && MilestonesData.data) ? MilestonesData.data.slice(indexOfFirstItem, indexOfLastItem) : [];
    console.log("data", data, columns);
    const modalHeaderTag = 'h2';
    const openEditModal = async (row) => {
        setSelectedRow(row);
        setEditModalOpen(true);
        try{
            const response = await axiosInstance.get(`/CM/cmFetch?cm_code=${row.cm_code}`);
            setselectedMilestonesData(response.data.data[0]);
        }catch(error){
            console.log("Error fetching Milestones data by code:",error)
        }
    };
    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        setSelectedImage(imageFile);
    };
    const openDeleteModal = (row) => {
        setSelectedRow(row);
        setDeleteModalOpen(true);
    };

    const closeEditModal = () => {
        setEditModalOpen(false);
    };
    
    const handleCreate = async () => {
        setIsClicked(true);
        const validationErrors = {};
        if (stateFormData.cm_name?.length < 5) {
            validationErrors.lifeatfss_description = "Input must be at least 5 characters long";
        }
        if (!stateFormData.cm_name) {
            validationErrors.lifeatfss_description = 'Please fill this field';
        }
        if (!stateFormData.cm_count) {
            validationErrors.lifeatfss_description = 'Please fill this field';
        }
        
        setIsValidate(validationErrors);
        if (Object.keys(validationErrors).length > 0) {
            return;
        }
        const config = {
            headers: {
                "content-type": "application/json",
                "x-auth-token": token
            }
        };
        const api = "/CM/cmCreation";
        try{
            const requestData = JSON.stringify(stateFormData);
    
            // Make a POST request with the JSON data
            const response = await axiosInstance.post(api, requestData, config);
    
            console.log("cm creation response DATA", response.data);
    
            // Refresh the Milestones data after creation
            MilestonesGetApi();
    
            // Close the modal
            toggle();
    
            
            setFormData({});
            // Swal.fire({
            //     icon: 'success',
            //     title: 'Success',
            //     text: 'Milestone added successfully!',
            //     confirmButtonColor: '#3085d6',
            //     confirmButtonText: 'OK'
           
            // });
        } catch (error) {
            console.log("cm creation Error from API", error);
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Error',
            //     text: 'Milestone is not created.',
            //     confirmButtonColor: '#d33',
            //     confirmButtonText: 'OK'
            // });
        }
    };
    
   
    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
    };

    const handleEdit = async () => {
        // Implement your edit logic here
        console.log("Edit row:", selectedRow);
        setIsClicked(true);
        const validationErrors = {};

        const validationRules = {
            cm_name: { required: true, minLength: 5 },
            cm_count: { required: true},
        };
        
        for (const [fieldName, rules] of Object.entries(validationRules)) {
            const value = stateFormData[fieldName] || selectedRow[fieldName];
            if (rules.required && (!value || value === "")) {
                validationErrors[fieldName] = 'Please fill this field';
            } else if (rules.minLength && value && value.length < rules.minLength) {
                validationErrors[fieldName] = `Please fill this field atleast ${rules.minLength} characters long`;
            }
        }

        setIsValidate(validationErrors);

        // Check if there are any validation errors
        if (Object.keys(validationErrors).length > 0) {
            return; // Stop further execution if there are validation errors
        }
        const formData=new FormData();
        formData.append("cm_code",selectedRow.cm_code);
        formData.append("cm_name",stateFormData.cm_name||selectedRow.cm_name);
        formData.append("cm_count",stateFormData.cm_count||selectedRow.cm_count);
        try{
            const response = await axiosInstance.post("/CM/cmCreation",formData,{
                headers:{
                    "Content-Type":"application/json",
                    "x-auth-token":token
                }
            });
            MilestonesGetApi();
            closeEditModal();
            // Swal.fire({
            //     icon:'Success',
            //     title:'Success',
            //     text:'Milestones updated successfullly !',
            //     confirmButtonColor:'#3085d6',
            //     confirmButtonText:'OK'
            // });
        }catch(error) {
            // Swal.fire({
            //     icon:'error',
            //     title:'error',
            //     text:'failed to update milestones',
            //     confirmButtonColor:'#d33',
            //     confirmButtonText:'OK'
            // });

        }
     
    };

    const handleDelete = async () => {
        try {
            // Call the delete blog API with the blog code
            console.log("selectedRow",selectedRow)
            const cmCode = selectedRow.cm_code;
            const response = await axiosInstance.post("/CM/cmDelete", { cm_code: selectedRow.cm_code});
    
            // Handle the success response
            console.log("Delete blog response:", response.data);
    
            // Optionally, you can refresh the blogs data after deletion
            MilestonesGetApi();
    
            // Close the delete modal
            closeDeleteModal();
            // Swal.fire({
            //     icon: 'success',
            //     title: 'Success',
            //     text: 'Milestone deleted successfully!',
            //     confirmButtonColor: '#3085d6',
            //     confirmButtonText: 'OK'
            // });
        } catch (error) {
            // Handle the error
            console.log("Error deleting blog:", error);
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Error',
            //     text: 'Milestone is not Deleted',
            //     confirmButtonColor: '#d33',
            //     confirmButtonText: 'OK'
            // });
        }
    };
   
    const handleInputChange = (inputName, val) => {
        console.log("Event data change", inputName, "   val", val);
        setFormData((prevData) => {
            return {
                ...prevData,
                [inputName]: val,
            }
        })
        setIsValidate((prevValidation) => ({
            ...prevValidation,
            [inputName]: !!val, // Update validation status based on whether the field has a value
        }));
    };
    return (
        <>
            <Container className="mt-5" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="bg-transparent d-flex justify-content-between">
                                <h2 className="mb-0">Milestones</h2>
                                <Button color="primary" onClick={toggle}>Add</Button>
                            </CardHeader>
                            <CardBody>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            {columns.map((column, index) => (
                                                <th key={index}>{column}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {currentItems.map((row, rowIndex) => (
                                            <tr key={rowIndex}>
                                                 
                                                 <td>{row['cm_name']}</td>
                                                 <td>{row['cm_count']}</td>
                                                
                                                 
                                                 
                                                <td>
                                               
                                                                <button className="btn btn-primary" onClick={() => openEditModal(row)}>
                                                                    edit
                                                                </button>
                                                                <button className="btn btn-default" onClick={() => openDeleteModal(row)}>
                                                                    delete
                                                           </button>
                                                    </td>
                                              
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                {
                                    data.length > itemsPerPage && (
                                        <ReactPaginate
                                            activePage={currentPage}
                                            itemsCountPerPage={itemsPerPage}
                                            totalItemsCount={data.length}
                                            pageRangeDisplayed={5}
                                            onChange={handlePageChange}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            innerClass="pagination justify-content-center"
                                        />
                                    )
                                }
                            </CardBody>
                        </Card>
                    </div>
                </Row>
                <Modal isOpen={modal} toggle={toggle} centered size="lg">
                    <ModalHeader toggle={toggle} tag={modalHeaderTag}>Add Milestones</ModalHeader>
                    <ModalBody className="bg-secondary">
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="cm_name"
                                    >
                                   COMPANY NAME
                                    </label>
                                    <Input
                                        className={`form-control-alternative ${
                                            isValidate.cm_name ? (isValidate.cm_name?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        defaultValue=""
                                        id="cm_name"
                                        placeholder="Please Enter name"
                                        type="text"
                                        name="cm_name"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, cm_name: true })}
                                        required
                                        aria-describedby="name-error"
                                    />
                                    {isClicked && isValidate.cm_name && isValidate.cm_name?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.cm_name}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="cm_count"
                                    >
                                    COUNT OF MILESTONES
                                    </label>
                                    <Input
                                        className={`form-control-alternative ${
                                            isValidate.cm_count ? (isValidate.cm_count?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        defaultValue=""
                                        id="cm_count"
                                        placeholder="Please Enter count"
                                        type="text"
                                        name="cm_count"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, cm_count: true })}
                                        required
                                        aria-describedby="name-error"
                                    />
                                    {isClicked && isValidate.cm_count && isValidate.cm_count?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.cm_count}</small>
                                )}
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleCreate}>
                            Add
                        </Button>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={successModal} toggle={toggleSuccessModal} centered>
                    <ModalBody>
                        <Alert color="success">Milestones added successfully!</Alert>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={toggleSuccessModal}>
                            OK
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* Edit Modal */}
                <Modal isOpen={isEditModalOpen} toggle={closeEditModal} centered size="lg">
                    <ModalHeader toggle={closeEditModal} tag={modalHeaderTag}>Edit Row</ModalHeader>
                    <ModalBody className="bg-secondary">
                        <Row>
                        <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="cm_name"
                                    >
                                 COMPANY NAME
                                    </label>
                                    <Input
                                        className={`form-control-alternative ${
                                            isValidate.cm_name ? (isValidate.cm_name?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        defaultValue={selectedMilestonesData.cm_name}
                                        id="cm_name"
                                        name="cm_name"
                                        placeholder=""
                                        type="text"
                                        onChange={(e)=> handleInputChange(e.target.name,e.target.value)}
                                    />
                                    {isClicked && isValidate.cm_name && isValidate.cm_name?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.cm_name}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="cm_count"
                                    >
                                    COUNT OF MILESTONES
                                    </label>
                                    <Input
                                        className={`form-control-alternative ${
                                            isValidate.cm_count ? (isValidate.cm_count?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        defaultValue={selectedMilestonesData.cm_count|| ""}
                                        id="Description"
                                        placeholder=""
                                        type="text"
                                        name="cm_count"
                                        onChange={(e)=> handleInputChange(e.target.name,e.target.value)}
                                    />
                                    {isClicked && isValidate.cm_count && isValidate.cm_count?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.cm_count}</small>
                                )}
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleEdit}>
                            Save
                        </Button>{" "}
                        <Button color="secondary" onClick={closeEditModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* Delete Modal */}
                <Modal isOpen={isDeleteModalOpen} toggle={closeDeleteModal} centered size="lg">
                    <ModalHeader toggle={closeDeleteModal} tag={modalHeaderTag}>Delete</ModalHeader>
                    <ModalBody className="bg-secondary">
                        Are you sure you want to delete this row?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={handleDelete}>
                            Delete
                        </Button>{" "}
                        <Button color="secondary" onClick={closeDeleteModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </Container>
        </>
    );
};

export default Milestones;
