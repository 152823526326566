import { useState, useEffect } from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    CustomInput,
    UncontrolledTooltip,
    Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Table, Alert,FormText
} from "reactstrap";
import ReactPaginate from 'react-js-pagination';
import axiosInstance from '../../axiosInterceptor';
import MyS3Component from "../../components/s3/s3";
import Swal from "sweetalert2";

// import Header from "components/Headers/Header.js";
//import Table from "../../components/Table/Table";

const data = [
    { id: 1, icons: 'John Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 1', action: 'edit,delete', status: '' },
    { id: 2, icons: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 2', action: 'edit,delete', status: 'inactive' },
    { id: 3, icons: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 3', action: 'edit,delete', status: 'active' },
    { id: 4, icons: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 4', action: 'edit,delete', status: 'inactive' },
    { id: 5, icons: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 5', action: 'edit,delete', status: 'active' },
    // Add more data as needed
];


const columns = ['LEADER NAME', 'LEADER DESIGNATION', 'LEADER IMAGE', 'ACTION'];
const Leader = () => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const itemsPerPage = 5; // Number of items to display per page
    const [currentPage, setCurrentPage] = useState(1);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [successModal, setSuccessModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    // const [formData, setFormData] = useState({});
    const [leaderData, SetleaderData] = useState([]);
    const toggleSuccessModal = () => setSuccessModal(!successModal);
    const [stateFormData, setFormData] = useState({});
    const [isClicked, setIsClicked] = useState(false);
    const [isValidate, setIsValidate] = useState({ leader_name: false, leader_description: false,leader_hierarchy:false,leader_image: false, })
    const[selectedLeaderData,setselectedLeaderData]=useState({});
    const [isImageVisible, setIsImageVisible] = useState(true);

    const [updatedLeaderImage,setUpdatedLeaderImage]=useState({})
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        const indexOfLastItem = pageNumber * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = leaderData?.slice(indexOfFirstItem, indexOfLastItem);
        setTableData(currentItems);
    };
    useEffect(() => {
        leaderGetApi()
        console.log("leaderData", leaderData)
    }, []);
    const handleRemoveImage = () => {
       
        setIsImageVisible(false);
    };
    console.log("selectedLeaderData",selectedLeaderData)
    const token = localStorage.getItem('JWT');
    const leaderGetApi = () => axiosInstance.get("leader/leaderRetrieve").then(response => {
        console.log("leadership response DATA", response.data);
        SetleaderData(response.data.data);
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = response.data.data?.slice(indexOfFirstItem, indexOfLastItem);
        setTableData(currentItems);

    }).catch(error => {
        console.log("Error from API", error);
    })


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = (leaderData && leaderData.data) ? leaderData.data.slice(indexOfFirstItem, indexOfLastItem) : [];
    console.log("data", data, columns);
    const modalHeaderTag = 'h2';
    const openEditModal = async (row) => {
        setSelectedRow(row);
        setEditModalOpen(true);
        try{
            const response =await axiosInstance.get(`/leader/leaderRetrieve?leader_code=${row.leader_code}`);
            setselectedLeaderData(response.data.data[0]);
        }catch(error){
            console.log("Error fetching leadership data by code:",error)
        }
    };
    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        setSelectedImage(imageFile);
    };
    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    
        if (file && !allowedTypes.includes(file.type)) {
            alert('Please select a valid image file (PNG or JPG).');
            e.target.value = '';
        } else {
            handleInputChange(e.target.name, file);
        }
    };
    const openDeleteModal = (row) => {
        setSelectedRow(row);
        setDeleteModalOpen(true);
    };

    const closeEditModal = () => {
        setEditModalOpen(false);
    };

    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
    };
    const handleCreate = async() => {
        setIsClicked(true);
        const validationErrors = {};
        if (stateFormData.leader_name?.length < 5) {
            validationErrors.leader_name = "Input must be at least 5 characters long";
        }
        if (!stateFormData.leader_name) {
            validationErrors.leader_name = 'Please fill this field';
        }
        if (stateFormData.leader_description?.length < 5) {
            validationErrors.leader_description = "Input must be at least 5 characters long";
        }
        if (!stateFormData.leader_description) {
            validationErrors.leader_description = 'Please fill this field';
        }
        if (!stateFormData.leader_hierarchy) {
            validationErrors.leader_hierarchy = 'Please fill this field';
        }
        if (!stateFormData.leader_image) {
            validationErrors.leader_image = 'Please fill this field';
        }
        setIsValidate(validationErrors);
        if (Object.keys(validationErrors).length > 0) {
            return;
        }
                const config = {
            headers: {
                "content-type": "multipart/form-data"
            }
        };
        const api = "/leader/leaderCreation";
        try {
            const response = await axiosInstance.post(api, stateFormData, config);
            console.log("leader creation response DATA", response.data);
            leaderGetApi();
            toggle();
            setFormData({});
            // Swal.fire({
            //     icon: 'success',
            //     title: 'Success',
            //     text: 'leadership added successfully!',
            //     confirmButtonColor: '#3085d6',
            //     confirmButtonText: 'OK'
            // });
    } catch (error) {
        console.log("leader creation Error from API", error);

        // Swal.fire({
        //     icon: 'error',
        //     title: 'Error',
        //     text: 'leadership is not created.',
        //     confirmButtonColor: '#d33',
        //     confirmButtonText: 'OK'
        // });
    }
};

    const handleEdit = async () => {
        setIsClicked(true);
        const validationErrors = {};

        const validationRules = {
            leader_name: { required: true, minLength: 5 },
            leader_description: { required: true, minLength: 5 },
            leader_hierarchy: { required: true, minLength: 5 },
            leader_image: { required: true }
        };
        
        for (const [fieldName, rules] of Object.entries(validationRules)) {
            const value = stateFormData[fieldName] || selectedRow[fieldName];
            if (rules.required && (!value || value === "")) {
                validationErrors[fieldName] = 'Please fill this field';
            } else if (rules.minLength && value && value.length < rules.minLength) {
                validationErrors[fieldName] = `Please fill this field atleast ${rules.minLength} characters long`;
            }
        }

        setIsValidate(validationErrors);

        // Check if there are any validation errors
        if (Object.keys(validationErrors).length > 0) {
            return; // Stop further execution if there are validation errors
        }
        const formData=new FormData();
        formData.append("leader_code",selectedRow.leader_code);
        formData.append("leader_name",stateFormData.leader_name||selectedRow.leader_name);
        formData.append("leader_description",stateFormData.leader_description||selectedRow.leader_description);
        formData.append("leader_heirarchy",stateFormData.leader_hierarchy||selectedRow.leader_hierarchy);
        formData.append("leader_image",stateFormData.leader_image||"");
        try{
            const response = await axiosInstance.put("/leader/leaderEdit",formData,{
                headers:{
                    "content-type": "multipart/form-data",
                    "x-auth-token": token
                }
            });
            const updatedLeaderImage = response.data.leader_image;
            setUpdatedLeaderImage(updatedLeaderImage);
        console.log("Edit leadership response:",response.data.data);
        leaderGetApi();
        closeEditModal();
        // Swal.fire({
        //     icon:"success",
        //     title:"succsess",
        //     text:'leadership updated successfully!',
        //     confirmButtonColor:"#3085d6",
        //     confirmButtonText:'OK'
        // });
    }catch (error) {
        // Swal.fire({
        //     icon:'error',
        //     title:'error',
        //     text:'failed to update Blog',
        //     confirmButtonColor:"#d33",
        //     confirmButtonText:'OK'
        // });
    }
 };

    const handleDelete = async () => {
        try {
            // Call the delete blog API with the blog code
            const response = await axiosInstance.delete(`/leader/leaderDelete?leader_code=${selectedRow.leader_code}`);
            //{ leaderCode: selectedRow.leaderCode }
            // Handle the success response
            console.log("Delete leader response:", response.data);
    
            // Optionally, you can refresh the blogs data after deletion
            leaderGetApi();
    
            // Close the delete modal
            closeDeleteModal();
            // Swal.fire({
            //     icon: 'success',
            //     title: 'Success',
            //     text: 'leadership deleted successfully!',
            //     confirmButtonColor: '#3085d6',
            //     confirmButtonText: 'OK'
            // });
        } catch (error) {
            // Handle the error
            console.log("Error deleting leader:", error);
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Error',
            //     text: 'leadership is not Deleted',
            //     confirmButtonColor: '#d33',
            //     confirmButtonText: 'OK'
            // });
        }
    };
    const handleInputChange = (inputName, val) => {
        console.log("Event data change", inputName, "   val", val);
        if(inputName ==='leader_image'){
            const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            if (val && !allowedTypes.includes(val.type)) {
                alert('Please select a valid image file (PNG or JPG).');
            }
            setFormData((prevData) => {
                return {
                    ...prevData,
                    [inputName]: val,
                }
            })
            setIsValidate((prevValidation) => ({
                ...prevValidation,
                [inputName]: !!val,
            }));

        }
        setFormData((prevData) => {
            return {
                ...prevData,
                [inputName]: val,
            }
        })
        setIsValidate((prevValidation) => ({
            ...prevValidation,
            [inputName]: !!val, // Update validation status based on whether the field has a value
        }));
    };
    return (
        <>
            <Container className="mt-5" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="bg-transparent d-flex justify-content-between">
                                <h2 className="mb-0">leader</h2>
                                <Button color="primary" onClick={toggle}>Add</Button>
                            </CardHeader>
                            <CardBody>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            {columns.map((column, index) => (
                                                <th key={index}>{column}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.map((row, rowIndex) => (
                                            <tr key={rowIndex}>
                                                <td>{row['leader_name']}</td>
                                                <td>{row['leader_description']}</td>
                                                <td><MyS3Component key={row['leader_code']} imageUrl={row['leader_image']} /></td>
                                               

                                                <td>

                                                    <button className="btn btn-primary" onClick={() => openEditModal(row)}>
                                                        edit
                                                    </button>
                                                    <button className="btn btn-default" onClick={() => openDeleteModal(row)}>
                                                        delete
                                                    </button>
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                {
                                    leaderData.length > itemsPerPage && (
                                        <ReactPaginate
                                            activePage={currentPage}
                                            itemsCountPerPage={itemsPerPage}
                                            totalItemsCount={leaderData.length}
                                            pageRangeDisplayed={5}
                                            onChange={handlePageChange}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            innerClass="pagination justify-content-center"
                                        />
                                    )
                                }
                            </CardBody>
                        </Card>
                    </div>
                </Row>
                <Modal isOpen={modal} toggle={toggle} centered size="lg">
                    <ModalHeader toggle={toggle} tag={modalHeaderTag}>Add leaders</ModalHeader>
                    <ModalBody className="bg-secondary">
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="leader_name"
                                    >
                                        leader Name
                                    </label>
                                    <Input
                                          className={`form-control-alternative ${
                                            isValidate.leader_name ? (isValidate.leader_name?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        defaultValue=""
                                        id="leader_name"
                                        placeholder="Please Enter name"
                                        type="text"
                                        name="leader_name"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, leader_name: true })}
                                        required
                                        aria-describedby="name-error"
                                        onKeyDown={(e) => {
                                            if (e.target.value === '' && !isNaN(parseInt(e.key))) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                         {isClicked && isValidate.leader_name && isValidate.leader_name?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.leader_name}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Description"
                                    >
                                    leader description
                                    </label>
                                    <Input
                                          className={`form-control-alternative ${
                                            isValidate.leader_description ? (isValidate.leader_description?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        defaultValue=""
                                        id="Description"
                                        placeholder="Please Enter description"
                                        type="text"
                                        name="leader_description"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, leader_description: true })}
                                        required
                                        aria-describedby="name-error"
                                    />
                                    
                                    {isClicked && isValidate.leader_description && isValidate.leader_description?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.leader_description}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Description"
                                    >
                                      leader hierarchy
                                    </label>
                                    <Input
                                          className={`form-control-alternative ${
                                            isValidate.leader_hierarchy ? (isValidate.leader_hierarchy?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        defaultValue=""
                                        id="subject"
                                        rows={6}
                                        placeholder="Please Enter heirarchy"
                                        type="textarea"
                                        name="leader_hierarchy"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, leader_hierarchy: true })}
                                        required
                                        aria-describedby="name-error"
                                        
                                    />
                                      {isClicked && isValidate.leader_hierarchy && isValidate.leader_hierarchy?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.leader_hierarchy}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label for="leader_image">leader Image</label>
                                    <Input type="file" id="leader_image" name="leader_image" onChange={(e) => handleInputChange(e.target.name, e.target.files[0])}
                                    accept=".png, .jpg, .jpeg"
                                     onBlur={() => setIsValidate({ ...isValidate, leader_image: true })}
                                     required
                                     aria-describedby="name-error"/>
                                    <FormText color="muted">
                                    Please select .png or .jpg images only within 1024x768px.
                                    </FormText>
                                    {isClicked && isValidate.leader_image && isValidate.leader_image?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.leader_image}</small>
                                )}
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleCreate}>
                            Add
                        </Button>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={successModal} toggle={toggleSuccessModal} centered>
                    <ModalBody>
                        <Alert color="success">leader added successfully!</Alert>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={toggleSuccessModal}>
                            OK
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* Edit Modal */}
                <Modal isOpen={isEditModalOpen} toggle={closeEditModal} centered size="lg">
                    <ModalHeader toggle={closeEditModal} tag={modalHeaderTag}>Edit Row</ModalHeader>
                    <ModalBody className="bg-secondary">
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="leader_name"
                                    >
                                        leader Name
                                    </label>
                                    <Input
                                          className={`form-control-alternative ${
                                            isValidate.leader_name ? (isValidate.leader_name?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        defaultValue={selectedLeaderData.leader_name}
                                        id="leader_name"
                                        placeholder=""
                                        name="leader_name"
                                        type="text"
                                        onChange={(e)=> handleInputChange(e.target.name,e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.target.value === '' && !isNaN(parseInt(e.key))) {
                                                e.preventDefault();
                                            }
                                        }}
                                        
                                    />
                                     {isClicked && isValidate.leader_name && isValidate.leader_name?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.leader_name}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="leader_description"
                                    >
                                     leader description
                                    </label>
                                    <Input
                                          className={`form-control-alternative ${
                                            isValidate.lifeatfss_description ? (isValidate.lifeatfss_description?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        defaultValue={selectedLeaderData.leader_description|| ""}
                                        id="leader_description"
                                        placeholder=""
                                        type="text"
                                        name="leader_description"
                                        onChange={(e) =>
                                        handleInputChange(e.target.name,e.target.value)}
                                    />
                                     {isClicked && isValidate.lifeatfss_description && isValidate.lifeatfss_description?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.lifeatfss_description}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="leader_hierarchy"
                                    >
                                     leader hierarchy
                                    </label>
                                    <Input
                                          className={`form-control-alternative ${
                                            isValidate.leader_hierarchy ? (isValidate.leader_hierarchy?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        defaultValue={selectedLeaderData.leader_hierarchy || ""}
                                        id="leader_hierachy"
                                        placeholder=""
                                        type="text"
                                        name="leader_hierarchy"
                                        onChange={(e) =>
                                        handleInputChange(e.target.name,e.target.value)}
                                    />
                                     {isClicked && isValidate.leader_hierarchy && isValidate.leader_hierarchy?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.leader_hierarchy}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                            <FormGroup>
                                    <label for="leader_image">leader Image</label>
                                    <Input type="file" 
                                    id="leader_image" 
                                    name="leader_image" 
                                    onBlur={() => setIsValidate({ ...isValidate, image_url: true })}
                                    onChange={(e) => handleInputChange(e.target.name, e.target.files[0])}
                                    accept=".png, .jpg, .jpeg"
                                    required
                                    aria-describedby="name-error"
                                    />
                                    <FormText color="muted">
                                    Please select .png or .jpg images only within 1024x768px.
                                    </FormText>
                                    {isImageVisible && (updatedLeaderImage || selectedLeaderData.leader_image) ? (
                                        <div style={{ position: 'relative', display: 'inline-block' }}>
                                            <MyS3Component key={selectedLeaderData?.leader_code} imageUrl={selectedLeaderData?.leader_image ? selectedLeaderData?.leader_image : updatedLeaderImage} />
                                            <Button size="sm" style={{ position: 'absolute', top: 0, right: "-40px" }} onClick={handleRemoveImage}>
                                                X
                                            </Button>
                                        </div>
                                    ) : null}
                                    {isClicked && isValidate.leader_image && isValidate.leader_image?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.leader_image}</small>
                                )}
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleEdit}>
                            Save
                        </Button>{" "}
                        <Button color="secondary" onClick={closeEditModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* Delete Modal */}
                <Modal isOpen={isDeleteModalOpen} toggle={closeDeleteModal} centered size="lg">
                <ModalHeader toggle={closeDeleteModal} tag={modalHeaderTag}>Delete</ModalHeader>
                <ModalBody className="bg-secondary">
                    Are you sure you want to delete this leadership?
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={handleDelete}>
                        Yes
                    </Button>{" "}
                    <Button color="secondary" onClick={closeDeleteModal}>
                        No
                    </Button>
                </ModalFooter>
            </Modal>
            </Container>
        </>
    );
};

export default Leader;