import { useEffect, useState } from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    CustomInput,
    UncontrolledTooltip,
    Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Table, Alert, FormText
} from "reactstrap";
import ReactPaginate from 'react-js-pagination';
import axiosInstance from "../../axiosInterceptor";
import { Editor } from "primereact/editor";
import { CostExplorer } from "aws-sdk";
import Swal from "sweetalert2";
// core components
// import Header from "components/Headers/Header.js";
//import Table from "../../components/Table/Table";

const initialFormData = {
    job_list: [{
        joblist_title: '',
        joblist_description: ''
    }]
}


const columns = ['id', 'JOB TITLE', 'JOB LOCATION', 'ACTION'];
const Career = () => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const itemsPerPage = 5; // Number of items to display per page
    const [currentPage, setCurrentPage] = useState(1);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [successModal, setSuccessModal] = useState(false);
    const [CareerData, SetCareerData] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    // const [stateFormData]=useState(null);
    const [tableData, setTableData] = useState([]);
    const [isClicked, setIsClicked] = useState(false);
    const [isValidate, setIsValidate] = useState({})
    const [selectedCareerData, setselectedCareerData] = useState({});
    const toggleSuccessModal = () => setSuccessModal(!successModal);
    const [stateFormData, setFormData] = useState(initialFormData);
    console.log("formData is", stateFormData);
    useEffect(() => {
        CareerGetApi()
        console.log("CareerData", Career)
    }, []);
    const token = localStorage.getItem('JWT');
    const CareerGetApi = () => axiosInstance.get("career/getJobs").then(response => {
        console.log("Career response DATA", response.data);
        SetCareerData(response.data.data);
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = response.data?.data?.slice(indexOfFirstItem, indexOfLastItem);
        setTableData(currentItems);

    }).catch(error => {
        console.log("Error from API", error);
    })
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        const indexOfLastItem = pageNumber * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = CareerData?.slice(indexOfFirstItem, indexOfLastItem);
        setTableData(currentItems);
    };

    const modalHeaderTag = 'h2';
    const openEditModal = async (row) => {
        setSelectedRow(row);
        setEditModalOpen(true);
    };
    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        setSelectedImage(imageFile);
    };
    const openDeleteModal = (row) => {
        setSelectedRow(row);
        setDeleteModalOpen(true);
    };

    const closeEditModal = () => {
        setEditModalOpen(false);
    };

    const handleCreate = async () => {
        setIsClicked(true);

        // Validation checks
        const validationErrors = {};
        if (stateFormData.job_title?.length < 5) {
            validationErrors.job_title = "Input must be at least 5 characters long";
        }
        if (!stateFormData.job_title) {
            validationErrors.job_title = 'Please fill this field';
        }
        if (stateFormData.job_location?.length < 5) {
            validationErrors.job_location = "Input must be at least 5 characters long";
        }
        if (!stateFormData.job_location) {
            validationErrors.job_location = 'Please fill this field';
        }
        if (!stateFormData.job_mode) {
            validationErrors.job_mode = 'Please fill this field';
        }
        if (!stateFormData.country) {
            validationErrors.country = 'Please select this field';
        }
        stateFormData.job_list.forEach((job, index) => {
            if (job.joblist_title?.length < 5) {
                validationErrors[`joblist_title_${index}`] = "Input must be at least 5 characters long";
            }
            if (!job.joblist_title) {
                validationErrors[`joblist_title_${index}`] = 'Please fill this field';
            }
            if (job.joblist_description?.length < 5) {
                validationErrors[`joblist_description_${index}`] = "Input must be at least 5 characters long";
            }
            if (!job.joblist_description) {
                validationErrors[`joblist_description${index}`] = 'Please fill this field';
            }
        });
        setIsValidate(validationErrors);

        if (Object.keys(validationErrors).length > 0) {
            return;
        }

        // If no validation errors, continue with API call
        const config = {
            headers: {
                "content-type": "application/x-www-form-urlencoded",
                "x-auth-token": token
            }
        };
        const api = "career/jobCreation";
        try {
            console.log("formData", stateFormData);
            const response = await axiosInstance.post(api, stateFormData, config);
            console.log("Career creation response DATA", response.data);
            CareerGetApi();
            toggle();
            setFormData(initialFormData);
            // Display success popup

            // Swal.fire({
            //     icon: 'success',
            //     title: 'Success',
            //     text: 'careers added successfully!',
            //     confirmButtonColor: '#3085d6',
            //     confirmButtonText: 'OK'
            // });
        } catch(error) {
            console.log("Career creation Error from API", error);
            // Swal.fire({
            //     icon: 'success',
            //     title: 'Success',
            //     text: 'careers added successfully!',
            //     confirmButtonColor: '#3085d6',
            //     confirmButtonText: 'OK'
            // });
        
        }
    };
   
    const countries = [
        'India',
        'Canada',
        'USA',
    ];
    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
    };

    const handleEdit = async () => {
        setIsClicked(true);
        const validationErrors = {};

        const validationRules = {
            job_title: { required: true, minLength: 5 },
            job_location: { required: true, minLength: 5 },
            job_mode: { required: true, minLength: 5 },
            country: { required: true },
        };

        for (const [fieldName, rules] of Object.entries(validationRules)) {
            const value = stateFormData[fieldName] || selectedRow[fieldName];
            if (rules.required && (!value || value === "")) {
                validationErrors[fieldName] = 'Please fill this field';
            } else if (rules.minLength && value && value.length < rules.minLength) {
                validationErrors[fieldName] = `Please fill this field atleast ${rules.minLength} characters long`;
            }
        }

        setIsValidate(validationErrors);

        // Check if there are any validation errors
        if (Object.keys(validationErrors).length > 0) {
            return; // Stop further execution if there are validation errors
        }
        const config = {
            headers: {
                'content-Type': 'application/json',
                "x-auth-token": token
            }
        };
        const api = "career/jobEdit";
        console.log("req data to edit api", selectedRow);
        try {
            const response = await axiosInstance.put(api, selectedRow, config);
            console.log("Edit career response:", response);
            setSelectedRow({})
            CareerGetApi();
            closeEditModal();

            // Swal.fire({
            //     icon:'Success',
            //     title:'Success',
            //     text:'Career updated successfullly !',
            //     confirmButtonColor:'#3085d6',
            //     confirmButtonText:'OK'
            // });
        }catch(error){
            console.error("Error updating career:",error);
            // Swal.fire({
            //     icon:'error',
            //     title:'error',
            //     text:'failed to update Career',
            //     confirmButtonColor:'#d33',
            //     confirmButtonText:'OK'
            // });

          
        }

    };

    const handleDelete = async () => {
        try {
            // Call the delete blog API with the blog code

            const response = await axiosInstance.delete(`/career/jobDelete?job_code=${selectedRow.job_code}`,
                {
                    headers: {
                        'content-Type': 'application/x-www-form-urlencoded',
                        'accept': 'application/x-www-form-urlencoded',
                        "x-auth-token": token
                    }
                });
            // Handle the success response
            console.log("Delete blog response:", response.data);

            // Optionally, you can refresh the blogs data after deletion
            CareerGetApi();

            // Close the delete modal
            closeDeleteModal();
            // Swal.fire({
            //     icon: 'success',
            //     title: 'Success',
            //     text: 'Career deleted successfully!',
            //     confirmButtonColor: '#3085d6',
            //     confirmButtonText: 'OK'
            // });
        } catch (error) {
           
            console.log("Error deleting blog:", error);
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Error',
            //     text: 'Career is not Deleted',
            //     confirmButtonColor: '#d33',
            //     confirmButtonText: 'OK'
            // });
        }
    };
    const handleAddNewJobPoint = () => {
        setFormData((prevData) => {
            console.log("prevData is", prevData);
            const newJob = {
                joblist_title: '',
                joblist_description: ''
            };
            return {
                ...prevData,
                job_list: [...prevData?.job_list, newJob]
            };
        });
    };
    const handleEditNewJobPoint = () => {
        setSelectedRow((prevData) => {
            console.log("prevData in edit is", prevData);
            const newJob = {
                joblist_title: '',
                joblist_description: ''
            };
            return {
                ...prevData,
                job_list: [...prevData?.job_list, newJob]
            };
        });
    };
    const handleJobInputChange = (index, field, value) => {
        console.log("index ==========", index, field, value);

        setFormData((prevData) => {
            console.log("prevData state data is", prevData);
            const updatedJob = prevData.job_list.map((Job, idx) => {
                console.log("JOB DATA", Job);
                if (String(idx) === String(index)) {
                    console.log("       return ing data", { ...Job, [field]: value });
                    return { ...Job, [field]: value };
                }
                return Job;
            });
            console.log("updated Job", updatedJob);
            return {
                ...prevData,
                job_list: updatedJob,
            }
        });
    };
    const handleEditJobInputChange = (index, field, value) => {
        console.log("index ==========", index, field, value);

        setSelectedRow((prevData) => {
            console.log("prevData state data is", prevData);
            const updatedJob = prevData.job_list.map((Job, idx) => {
                console.log("JOB DATA", Job);
                if (String(idx) === String(index)) {
                    console.log("       return ing data", { ...Job, [field]: value });
                    return { ...Job, [field]: value };
                }
                return Job;
            });
            console.log("updated Job", updatedJob);
            return {
                ...prevData,
                job_list: updatedJob,
            }
        });
    };

    const handleRemoveStory = (index) => {
        const updatedStories = [...stateFormData.job_list];
        updatedStories.splice(index, 1);
        setFormData({ ...stateFormData, job_list: updatedStories });
    };
    const handleEditRemoveStory = (index) => {
        const updatedStories = [...selectedRow.job_list];
        updatedStories.splice(index, 1);
        setSelectedRow({ ...selectedRow, job_list: updatedStories });
    };

    const handleInputChange = (inputName, val) => {
        console.log("Event data change", inputName, "   val", val);
        if (inputName === "country") {
            setFormData((prevData) => ({
                ...prevData,
                [inputName]: val,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [inputName]: val,
            }));
            setIsValidate((prevValidation) => ({
                ...prevValidation,
                [inputName]: !!val,
            }));
        }
    };
    
    const handleEditInputChange = (inputName, val) => {
        console.log("Event data change", inputName, "   val", val);
        if (inputName === "country") {
            setSelectedRow((prevData) => ({
                ...prevData,
                [inputName]: val,
            }));
        } else {
            setSelectedRow((prevData) => ({
                ...prevData,
                [inputName]: val,
            }));
            setIsValidate((prevValidation) => ({
                ...prevValidation,
                [inputName]: !!val,
            }));
        }
    };

    return (
        <>
            <Container className="mt-5" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="bg-transparent d-flex justify-content-between">
                                <h2 className="mb-0">Career</h2>
                                <Button color="primary" onClick={toggle}>Add</Button>
                            </CardHeader>
                            <CardBody>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            {columns.map((column, index) => (
                                                <th key={index}>{column}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.map((row, rowIndex) => (
                                            <tr key={rowIndex}>
                                                <td>{rowIndex + 1}</td>
                                                <td>{row['job_title']}</td>
                                                <td>{row['job_location']}</td>

                                                <td>

                                                    <button className="btn btn-primary" onClick={() => openEditModal(row)}>
                                                        edit
                                                    </button>
                                                    <button className="btn btn-default" onClick={() => openDeleteModal(row)}>
                                                        delete
                                                    </button>
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                {
                                    CareerData.length > itemsPerPage && (
                                        <ReactPaginate
                                            activePage={currentPage}
                                            itemsCountPerPage={itemsPerPage}
                                            totalItemsCount={CareerData.length}
                                            pageRangeDisplayed={5}
                                            onChange={handlePageChange}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            innerClass="pagination justify-content-center"
                                        />
                                    )
                                }
                            </CardBody>
                        </Card>
                    </div>
                </Row>
                <Modal isOpen={modal} toggle={toggle} centered size="lg">
                    <ModalHeader toggle={toggle} tag={modalHeaderTag}>Add Career</ModalHeader>
                    <ModalBody className="bg-secondary">
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Description"
                                    >
                                        JOB TITLE
                                    </label>
                                    <Input
                                        className={`form-control-alternative ${isValidate.job_title ? (isValidate.job_title?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                            }`}
                                        defaultValue=""
                                        id="name"
                                        placeholder="Please Enter name"
                                        type="text"
                                        name="job_title"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, job_title: true })}
                                        required
                                        aria-describedby="name-error"
                                        onKeyDown={(e) => {
                                            if (e.target.value === '' && !isNaN(parseInt(e.key))) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    {isClicked && isValidate.job_title && isValidate.job_title?.length > 0 && (
                                        <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.job_title}</small>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Description"
                                    >
                                        JOB LOCATION
                                    </label>
                                    <Input
                                        className={`form-control-alternative ${isValidate.job_location ? (isValidate.job_location?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                            }`}
                                        defaultValue=""
                                        placeholder="Please Enter count"
                                        type="text"
                                        name="job_location"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, job_location: true })}
                                        required
                                        aria-describedby="name-error"
                                        onKeyDown={(e) => {
                                            if (e.target.value === '' && !isNaN(parseInt(e.key))) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    {isClicked && isValidate.job_location && isValidate.job_location?.length > 0 && (
                                        <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.job_location}</small>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Description"
                                    >
                                        JOB MODE
                                    </label>
                                    <Input
                                        className={`form-control-alternative ${isValidate.job_mode ? (isValidate.job_mode?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                            }`}
                                        defaultValue=""
                                        id="name"
                                        placeholder="Please Enter count"
                                        type="text"
                                        name="job_mode"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, job_mode: true })}
                                        required
                                        aria-describedby="name-error"
                                    />
                                    {isClicked && isValidate.job_mode && isValidate.job_mode?.length > 0 && (
                                        <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.job_mode}</small>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="country">
                                            Country
                                    </label>
                                    <Input
                                        className={`form-control-alternative ${isValidate.country ? (isValidate.country.length > 0 ? 'is-invalid' : 'is-valid') : ''}`}
                                        type="select"
                                        name="country"
                                        id="country"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, country: true })}
                                        aria-describedby="country-error"
                                    >
                                        <option value="">Select a country</option>
                                        {countries.map((country, index) => (
                                            <option key={index} value={country}>
                                                {country}
                                            </option>
                                        ))}
                                    </Input>
                                    {isClicked && isValidate.country && isValidate.country.length > 0 && (
                                        <small className="text-danger my-0 d-block fz-12px mt-3 fw-100" id="country-error">{isValidate.country}</small>
                                    )}

                                </FormGroup>
                            </Col>
                        </Row>
                        <Col lg="12">
                            {stateFormData.job_list?.map((Job, index) => (
                                <div key={index} style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px', position: 'relative' }}>
                                    <Button size="sm" style={{ position: 'absolute', top: '5px', right: '5px' }} onClick={() => handleRemoveStory(index)}>
                                        X
                                    </Button>
                                    <FormGroup>
                                        <label>Job list:</label>
                                        <Input
                                            type="text"
                                            name="joblist_title"
                                            value={stateFormData.joblist_title}
                                            onChange={(e) => handleJobInputChange(index, 'joblist_title', e.target.value)}

                                        />

                                    </FormGroup>
                                    <FormGroup>
                                        <label>Job Description:</label>
                                        <Editor key={index} onTextChange={(e) => handleJobInputChange(index, 'joblist_description', e.htmlValue)}
                                            style={{ height: '100px' }}
                                            name="joblist_description"

                                        />

                                    </FormGroup>
                                </div>
                            ))}
                        </Col>
                        <Button style={{ float: "right" }} color="info" onClick={handleAddNewJobPoint}>
                            +Add Job
                        </Button>

                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleCreate}>
                            Add
                        </Button>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={successModal} toggle={toggleSuccessModal} centered>
                    <ModalBody>
                        <Alert color="success">Career added successfully!</Alert>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={toggleSuccessModal}>
                            OK
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* Edit Modal */}
                <Modal isOpen={isEditModalOpen} toggle={closeEditModal} centered size="lg">
                    <ModalHeader toggle={closeEditModal} tag={modalHeaderTag}>Edit Row</ModalHeader>
                    <ModalBody className="bg-secondary">
                        <Row>

                            <ModalBody className="bg-secondary">
                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="job_title"
                                            >
                                                JOB TITLE
                                            </label>
                                            <Input
                                                className={`form-control-alternative ${isValidate.job_title ? (isValidate.job_title?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                                    }`}
                                                defaultValue={selectedRow.job_title || ""}
                                                id="job_title"
                                                placeholder=""
                                                type="text"
                                                name="job_title"
                                                onChange={(e) => handleEditInputChange(e.target.name, e.target.value)}
                                                onKeyDown={(e) => {
                                                    if (e.target.value === '' && !isNaN(parseInt(e.key))) {
                                                        e.preventDefault();
                                                    }
                                                }}

                                            />
                                            {isClicked && isValidate.job_title && isValidate.job_title?.length > 0 && (
                                                <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.job_title}</small>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="job_location"
                                            >
                                                JOB LOCATION
                                            </label>
                                            <Input
                                                className={`form-control-alternative ${isValidate.job_location ? (isValidate.job_location?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                                    }`}
                                                defaultValue={selectedRow.job_location || ""}
                                                placeholder=""
                                                type="text"
                                                name="job_location"
                                                onChange={(e) => handleEditInputChange(e.target.name, e.target.value)}
                                                onKeyDown={(e) => {
                                                    if (e.target.value === '' && !isNaN(parseInt(e.key))) {
                                                        e.preventDefault();
                                                    }
                                                }}


                                            />
                                            {isClicked && isValidate.job_location && isValidate.job_location?.length > 0 && (
                                                <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.job_location}</small>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="job_mode"
                                            >
                                                JOB MODE
                                            </label>
                                            <Input
                                                className={`form-control-alternative ${isValidate.job_mode ? (isValidate.job_mode?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                                    }`}
                                                defaultValue={selectedRow.job_mode || ""}
                                                id="job_mode"
                                                placeholder=""
                                                type="text"
                                                name="job_mode"
                                                onChange={(e) => handleEditInputChange(e.target.name, e.target.value)}
                                                onKeyDown={(e) => {
                                                    if (e.target.value === '' && !isNaN(parseInt(e.key))) {
                                                        e.preventDefault();
                                                    }
                                                }}

                                            />
                                            {isClicked && isValidate.job_mode && isValidate.job_mode?.length > 0 && (
                                                <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.job_mode}</small>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="jobMode">
                                                country
                                            </label>
                                            <Input
                                                className={`form-control-alternative ${isValidate.country ? (isValidate.country.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                                    }`}
                                                type="select"
                                                name="country"
                                                id="country"
                                                defaultValue={selectedRow.country || ""}
                                                onChange={(e) => handleEditInputChange(e.target.name, e.target.value)} 
                                                aria-describedby="country-error"
                                            >
                                                <option value="">Select a country</option>
                                                {countries.map((country, index) => (
                                                    <option key={index} value={country}>
                                                        {country}
                                                    </option>
                                                ))}
                                            </Input>
                                            {isClicked && isValidate.country && isValidate.country.length > 0 && (
                                                <small className="text-danger my-0 d-block fz-12px mt-3 fw-100" id="country-error">{isValidate.country}</small>
                                            )}

                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Col lg="12">
                                    {selectedRow.job_list?.map((Job, index) => (
                                        <div key={index} style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px', position: 'relative' }}>
                                            <Button size="sm" style={{ position: 'absolute', top: '5px', right: '5px' }} onClick={() => handleEditRemoveStory(index)}>
                                                X
                                            </Button>
                                            <FormGroup>
                                                <label>Job title:</label>
                                                <Input
                                                    type="text"
                                                    name="joblist_title"
                                                    onChange={(e) => handleEditJobInputChange(index, 'joblist_title', e.target.value)}
                                                    value={Job.joblist_title || ""}
                                                    onKeyDown={(e) => {
                                                        if (e.target.value === '' && !isNaN(parseInt(e.key))) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <label>Job Description:</label>
                                                <Editor
                                                    key={index}
                                                    onTextChange={(e) => handleEditJobInputChange(index, 'joblist_description', e.htmlValue)}
                                                    style={{ height: '100px' }}
                                                    name="joblist_description"
                                                    value={Job.joblist_description || ""}
                                                />
                                            </FormGroup>
                                        </div>
                                    ))}
                                </Col>
                                <Button style={{ float: "right" }} color="info" onClick={handleEditNewJobPoint}>
                                    +Add job
                                </Button>

                            </ModalBody>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleEdit}>
                            Save
                        </Button>{" "}
                        <Button color="secondary" onClick={closeEditModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* Delete Modal */}
                <Modal isOpen={isDeleteModalOpen} toggle={closeDeleteModal} centered size="lg">
                    <ModalHeader toggle={closeDeleteModal} tag={modalHeaderTag}>Delete</ModalHeader>
                    <ModalBody className="bg-secondary">
                        Are you sure you want to delete this row?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={handleDelete}>
                            Delete
                        </Button>{" "}
                        <Button color="secondary" onClick={closeDeleteModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </Container>
        </>
    );
};
export default Career;