
import { useState,useEffect } from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
import axiosInstance from '../../axiosInterceptor';
import Swal from "sweetalert2";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    CustomInput,
    UncontrolledTooltip,
    Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Table,FormText
} from "reactstrap";
import ReactPaginate from 'react-js-pagination';
import MyS3Component from "../../components/s3/s3";
// core components
// import Header from "components/Headers/Header.js";
//import Table from "../../components/Table/Table";

const data = [
    { id: 1, image: 'John Doe', action: 'edit,delete', status: 'active' },
    { id: 2, image: 'Jane Doe', action: 'edit,delete', status: 'inactive' },
    { id: 3, image: 'Jane Doe', action: 'edit,delete', status: 'active' },
    { id: 4, image: 'Jane Doe', action: 'edit,delete', status: 'inactive' },
    { id: 5, image: 'Jane Doe', action: 'edit,delete', status: 'active' },
    { id: 6, image: 'John Doe', action: 'edit,delete', status: 'active' },
    { id: 7, image: 'Jane Doe', action: 'edit,delete', status: 'inactive' },
    { id: 8, image: 'Jane Doe', action: 'edit,delete', status: 'active' },
    { id: 9, image: 'Jane Doe', action: 'edit,delete', status: 'inactive' },
    { id: 10, image: 'Jane Doe', action: 'edit,delete', status: 'active' },
    // Add more data as needed
];


const columns = ['LifeatFss Description', 'LifeatFss Image', 'action'];
const column=['lifeatfss_description','lifeatfss_image','action']
const LifeatFss = () => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const itemsPerPage = 5; // Number of items to display per page
    const [currentPage, setCurrentPage] = useState(1);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [stateFormData, setFormData] = useState({});
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedImage,setSelectedImage]= useState(null)
    const [selectedRow, setSelectedRow] = useState(null);
    const [lifeatFssResData, setLifeatFssResData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [isClicked, setIsClicked] = useState(false);
    const [isValidate, setIsValidate] = useState({})
    const [isImageVisible, setIsImageVisible] = useState(true);
    const [updatedImageUrl,setUpdatedImageUrl]=useState({})
    const [selectedLifeatFSSData,setSelectedLifeatFSSData]= useState({})
    useEffect(() => {
        lifeatFssGetApi()
    }, []);
    console.log("selectedLifeatFSSData",selectedLifeatFSSData)
    const token = localStorage.getItem('JWT');
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    console.log("data", data, columns);
    const modalHeaderTag = 'h2';
    const openEditModal = async (row) => {
        setSelectedRow(row);
        setEditModalOpen(true);

        // Fetch service data by code
        try {
            const response = await axiosInstance.get(`/lifeatfss/lifeatfssRetrieve?lifeatfss_code=${row.lifeatfss_code}`);
            setSelectedLifeatFSSData(response.data.data[0]);
        } catch (error) {
            console.log("Error fetching service data by code:", error);
        }
    };
    const handleInputChange = (inputName, val) => {
        console.log("Event data change", inputName, "   val", val);
        if(inputName ==='lifeatfss_image'){
            const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            if (val && !allowedTypes.includes(val.type)) {
                alert('Please select a valid image file (PNG or JPG).');
            }
            setFormData((prevData) => {
                return {
                    ...prevData,
                    [inputName]: val,
                }
            })
            setIsValidate((prevValidation) => ({
                ...prevValidation,
                [inputName]: !!val,
            }));

        }
        setFormData((prevData) => {
            return {
                ...prevData,
                [inputName]: val,
            }
        })
        setIsValidate((prevValidation) => ({
            ...prevValidation,
            [inputName]: !!val, 
        }));
    };
    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        setSelectedImage(imageFile);
    };
    const handleRemoveImage = () => {
        // Handle the logic to remove the image
        setIsImageVisible(false);
    };
    const openDeleteModal = (row) => {
        setSelectedRow(row);
        setDeleteModalOpen(true);
    };

    const closeEditModal = () => {
        setEditModalOpen(false);
    };

    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
    };
    const handleCreate = async () => {
        setIsClicked(true);
        const validationErrors = {};
        if (stateFormData.lifeatfss_description?.length < 5) {
            validationErrors.lifeatfss_description = "Input must be at least 5 characters long";
        }
        if (!stateFormData.lifeatfss_description) {
            validationErrors.lifeatfss_description = 'Please fill this field';
        }
        if (!stateFormData.lifeatfss_image) {
            validationErrors.lifeatfss_image = 'Please fill this field';
        }
        setIsValidate(validationErrors);
        if (Object.keys(validationErrors).length > 0) {
            return;
        }
        const config = {
            headers: {
                "content-type": "multipart/form-data",
                "x-auth-token": token
            }
        };
        const api = "/lifeatfss/lifeatfssCreation";
        try {
            const response = await axiosInstance.post(api, stateFormData, config);
            console.log("Life at Fss creation response DATA", response.data);
            lifeatFssGetApi();
            toggle();
            setFormData({});
    
            // Display success popup
            // Swal.fire({
            //     icon: 'success',
            //     title: 'Success',
            //     text: 'LifeAtFSS added successfully!',
            //     confirmButtonColor: '#3085d6',
            //     confirmButtonText: 'OK'
            // });
        } catch (error) {
            console.log("Life at Fss creation Error from API", error);
            // Handle error if needed
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Error',
            //     text: 'LifeAtFSS is not created.',
            //     confirmButtonColor: '#d33',
            //     confirmButtonText: 'OK'
            // });
        }
    };
    
    const lifeatFssGetApi = async() => await axiosInstance.get("/lifeatfss/lifeatfssRetrieve").then(response => {
        setLifeatFssResData(response.data.data);
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = response.data?.data?.slice(indexOfFirstItem, indexOfLastItem);
        setTableData(currentItems);
    }).catch(error => {
        console.log("Error from API", error);
    })
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        const indexOfLastItem = pageNumber * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = lifeatFssResData?.slice(indexOfFirstItem, indexOfLastItem);
        setTableData(currentItems);
    };
    
    const handleEdit = async () => {
        setIsClicked(true);
        const validationErrors = {};

        const validationRules = {
            lifeatfss_description: { required: true, minLength: 5 },
            lifeatfss_image: { required: true},
        };
        
        for (const [fieldName, rules] of Object.entries(validationRules)) {
            const value = stateFormData[fieldName] || selectedRow[fieldName];
            if (rules.required && (!value || value === "")) {
                validationErrors[fieldName] = 'Please fill this field';
            } else if (rules.minLength && value && value.length < rules.minLength) {
                validationErrors[fieldName] = `Please fill this field atleast ${rules.minLength} characters long`;
            }
        }

        setIsValidate(validationErrors);

        
        if (Object.keys(validationErrors).length > 0) {
            return; // Stop further execution if there are validation errors
        }

        const formData = new FormData();
        formData.append("lifeatfss_code", selectedRow.lifeatfss_code);
        formData.append("lifeatfss_description", stateFormData.lifeatfss_description || selectedRow.lifeatfss_description);
        formData.append("lifeatfss_image", stateFormData.lifeatfss_image || ""); // Assuming image_url is the key for the file
        
        try {
            console.log("response api",formData)
            const response = await axiosInstance.put("/lifeatfss/lifeatfssEdit", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "x-auth-token": token
                }
            });
            const updatedImageUrl = response.data.image_url;
            setUpdatedImageUrl(updatedImageUrl);
            console.log("Edit service response:", response.data);
            lifeatFssGetApi();
            closeEditModal();
            // Swal.fire({
            //     icon: 'success',
            //     title: 'Success',
            //     text: 'LifeatFSS Updated successfully!',
            //     confirmButtonColor: '#3085d6',
            //     confirmButtonText: 'OK'
            // });
        } catch (error) {
            console.error("Error updating LifeatFSS:", error);
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Error',
            //     text: 'Failed to update LifeatFSS',
            //     confirmButtonColor: '#d33',
            //     confirmButtonText: 'OK'
            // });
        }
    };
    const handleDelete = async () => {
        try {
            const response = await axiosInstance.delete(`/lifeatfss/lifeatfssDelete?lifeatfss_code=${selectedRow.lifeatfss_code}`,{
                headers:{
                    'Content-Type': 'application/json',
                    'accept':'application/json',
                    'x-auth-token': token
                }
            });
            console.log("Delete LifeatFss response:", response.data);
            lifeatFssGetApi();
            closeDeleteModal();
            // Swal.fire({
            //     icon: 'success',
            //     title: 'Success',
            //     text: 'LifeatFSS deleted successfully!',
            //     confirmButtonColor: '#3085d6',
            //     confirmButtonText: 'OK'
            // });
        } catch (error) {
            console.log("Error deleting LifeatFss:", error);
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Error',
            //     text: 'LifeatFSS is not Deleted',
            //     confirmButtonColor: '#d33',
            //     confirmButtonText: 'OK'
            // });
        }
    };
    
    return (
        <>
            {/* <Header /> */}
            {/* Page content */}
            <Container className="mt-5" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="bg-transparent d-flex justify-content-between">
                                <h2 className="mb-0">LifeatFss</h2>
                                <Button color="primary" onClick={toggle}>Add</Button>
                            </CardHeader>
                            <CardBody>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            {columns.map((column, index) => (
                                                <th key={index}>{column}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.map((row, rowIndex) => (
                                            <tr key={rowIndex}>
                                                {column.map((column, columnIndex) => (
                                                    <td key={columnIndex}>
                                                        {column === 'action' ? (
                                                            <>
                                                                <button className="btn btn-primary" onClick={() => openEditModal(row)}>
                                                                    edit
                                                                </button>
                                                                <button className="btn btn-default" onClick={() => openDeleteModal(row)}>
                                                                    delete
                                                                </button>
                                                            </>
                                                        ) :
                                                            column === 'lifeatfss_image' ? (
                                                                <>
                                                                <MyS3Component key={row['lifeatfss_code']} imageUrl={row['lifeatfss_image']} />
                                                                </>
                                                            ) : column === 'lifeatfss_status' ? (
                                                                <>
                                                                    <CustomInput type="switch" id={`customSwitch${rowIndex}`} checked={row['lifeatfss_status'] === 'ACTIVE'} />
                                                                </>
                                                            ) : (
                                                                row[column]
                                                            )}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                {
                                    lifeatFssResData.length > itemsPerPage && (
                                        <ReactPaginate
                                            activePage={currentPage}
                                            itemsCountPerPage={itemsPerPage}
                                            totalItemsCount={lifeatFssResData.length}
                                            pageRangeDisplayed={5}
                                            onChange={handlePageChange}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            innerClass="pagination justify-content-center"
                                        />
                                    )
                                }
                            </CardBody>
                        </Card>
                    </div>
                </Row>
                <Modal isOpen={modal} toggle={toggle} centered size="lg">
                    <ModalHeader toggle={toggle} tag={modalHeaderTag}>Add LifeatFss</ModalHeader>
                    <ModalBody className="bg-secondary">
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Branch"
                                    >
                                        LifeatFss Description
                                    </label>
                                    <Input
                                        className={`form-control-alternative ${
                                            isValidate.lifeatfss_description ? (isValidate.lifeatfss_description?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        defaultValue=""
                                        id="Branch"
                                        placeholder="Please Enter Branch"
                                        type="text"
                                        name="lifeatfss_description"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, productName: true })}
                                        required
                                        aria-describedby="name-error"
                                    />
                                     {isClicked && isValidate.lifeatfss_description && isValidate.lifeatfss_description?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.lifeatfss_description}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label for="lifeatfssimage">LifeatFss Image</label>
                                    <Input type="file" id="lifeatfssimage" name="lifeatfss_image" 
                                    accept=".png, .jpg, .jpeg"onBlur={() => setIsValidate({ ...isValidate, productName: true })}
                                    onChange={(e) => handleInputChange(e.target.name, e.target.files[0])}
                                        required
                                        aria-describedby="name-error" />
                                    <FormText color="muted">
                                    Please select .png or .jpg images only within 1024x768px.
                                    </FormText>
                                    {isClicked && isValidate.lifeatfss_image && isValidate.lifeatfss_image?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.lifeatfss_image}</small>
                                )}
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleCreate}>
                            Add
                        </Button>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* Edit Modal */}
                <Modal isOpen={isEditModalOpen} toggle={closeEditModal} centered size="lg">
                    <ModalHeader toggle={closeEditModal} tag={modalHeaderTag}>Edit LifeAtFSS</ModalHeader>
                    <ModalBody className="bg-secondary">
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="lifeatfss_description"
                                    >
                                        LifeatFss Description
                                    </label>
                                    <Input
                                        className={`form-control-alternative ${
                                            isValidate.lifeatfss_description ? (isValidate.lifeatfss_description?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        defaultValue={selectedLifeatFSSData.lifeatfss_description || ""}
                                        id="lifeatfss_description"
                                        placeholder="Please Enter lifeatfss description"
                                        type="text"
                                        name="lifeatfss_description"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                    />
                                     {isClicked && isValidate.lifeatfss_description && isValidate.lifeatfss_description?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.lifeatfss_description}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label for="lifeatfssimage">LifeatFss Image</label>
                                    <Input type="file" id="lifeatfssimage" name="lifeatfss_image" handleFileInputChange
                                    accept=".png, .jpg, .jpeg"onBlur={() => setIsValidate({ ...isValidate, productName: true })}
                                        required
                                        onChange={(e) => handleInputChange(e.target.name, e.target.files[0])}
                                        aria-describedby="name-error" />
                                    <FormText color="muted">
                                    Please select .png or .jpg images only within 1024x768px.
                                    </FormText>
                                    {isImageVisible && (updatedImageUrl || selectedLifeatFSSData.lifeatfss_image) ? (
                                        <div style={{ position: 'relative', display: 'inline-block' }}>
                                            <MyS3Component key={selectedLifeatFSSData?.lifeatfss_code} imageUrl={selectedLifeatFSSData?.lifeatfss_image ? selectedLifeatFSSData?.lifeatfss_image : updatedImageUrl} />
                                            <Button size="sm" style={{ position: 'absolute', top: 0, right: "-40px" }} onClick={handleRemoveImage}>
                                                X
                                            </Button>
                                        </div>
                                    ) : null}
                                     {isClicked && isValidate.lifeatfss_image && isValidate.lifeatfss_image?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.lifeatfss_image}</small>
                                )}
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleEdit}>
                            Save
                        </Button>{" "}
                        <Button color="secondary" onClick={closeEditModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* Delete Modal */}
                <Modal isOpen={isDeleteModalOpen} toggle={closeDeleteModal} centered size="lg">
                <ModalHeader toggle={closeDeleteModal} tag={modalHeaderTag}>Delete</ModalHeader>
                <ModalBody className="bg-secondary">
                    Are you sure you want to delete this?
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={handleDelete}>
                        Yes
                    </Button>{" "}
                    <Button color="secondary" onClick={closeDeleteModal}>
                        No
                    </Button>
                </ModalFooter>
            </Modal>
            </Container>
        </>
    );
};

export default LifeatFss;
