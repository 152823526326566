
import { useState,useEffect } from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
import axiosInstance from '../../axiosInterceptor';
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    CustomInput,
    UncontrolledTooltip,
    Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Table,FormText
} from "reactstrap";
import ReactPaginate from 'react-js-pagination';
import MyS3Component from "../../components/s3/s3";
import Swal from "sweetalert2";
// core components
// import Header from "components/Headers/Header.js";
//import Table from "../../components/Table/Table";

const data = [
    { id: 1, image: 'John Doe', action: 'edit,delete', status: 'active' },
    { id: 2, image: 'Jane Doe', action: 'edit,delete', status: 'inactive' },
    { id: 3, image: 'Jane Doe', action: 'edit,delete', status: 'active' },
    { id: 4, image: 'Jane Doe', action: 'edit,delete', status: 'inactive' },
    { id: 5, image: 'Jane Doe', action: 'edit,delete', status: 'active' },
    { id: 6, image: 'John Doe', action: 'edit,delete', status: 'active' },
    { id: 7, image: 'Jane Doe', action: 'edit,delete', status: 'inactive' },
    { id: 8, image: 'Jane Doe', action: 'edit,delete', status: 'active' },
    { id: 9, image: 'Jane Doe', action: 'edit,delete', status: 'inactive' },
    { id: 10, image: 'Jane Doe', action: 'edit,delete', status: 'active' },
    // Add more data as needed
];


const columns = ['Client Name', 'Client Image', 'Client Description', 'action'];
const column=['client_name','image_url','client_description','action']
const Clients = () => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const itemsPerPage = 5; 
    const [currentPage, setCurrentPage] = useState(1);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [stateFormData, setFormData] = useState({});
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [clientsResData, setClientsResData] = useState([]);
    const [selectedImage,setSelectedImage]= useState(null)
    const [selectedRow, setSelectedRow] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [isClicked, setIsClicked] = useState(false);
    const [isValidate, setIsValidate] = useState({})
    const [selectedClientData,setSelectedClientData]= useState({})
    const [isImageVisible, setIsImageVisible] = useState(true);
    const [updatedImageUrl,setUpdatedImageUrl]=useState({})

    useEffect(() => {
        clientGetApi()
    }, []);
    const token = localStorage.getItem('JWT');
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    console.log("data", data, columns);
    const modalHeaderTag = 'h2';
    const openEditModal = async (row) => {
        setSelectedRow(row);
        setEditModalOpen(true);

        // Fetch service data by code
        try {
            const response = await axiosInstance.get(`/client/getclientsbyCode?client_code=${row.client_code}`);
            setSelectedClientData(response.data.data);
        } catch (error) {
            console.log("Error fetching service data by code:", error);
        }
    };
    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    
        if (file && !allowedTypes.includes(file.type)) {
            alert('Please select a valid image file (PNG or JPG).');
            e.target.value = '';
        } else {
            handleInputChange(e.target.name, file);
        }
    };
    const handleInputChange = (inputName, val) => {
        console.log("Event data change", inputName, "   val", val);
        if(inputName ==='image_url'){
            const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            if (val && !allowedTypes.includes(val.type)) {
                alert('Please select a valid image file (PNG or JPG).');
            }
            setFormData((prevData) => {
                return {
                    ...prevData,
                    [inputName]: val,
                }
            })
            setIsValidate((prevValidation) => ({
                ...prevValidation,
                [inputName]: !!val,
            }));

        }
        setFormData((prevData) => {
            return {
                ...prevData,
                [inputName]: val,
            }
        })
        setIsValidate((prevValidation) => ({
            ...prevValidation,
            [inputName]: !!val, 
        }));
    };
    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        setSelectedImage(imageFile);
    };
    const openDeleteModal = (row) => {
        setSelectedRow(row);
        setDeleteModalOpen(true);
    };
    const handleRemoveImage = () => {
        // Handle the logic to remove the image
        setIsImageVisible(false);
    };
    const closeEditModal = () => {
        setEditModalOpen(false);
    };

    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
    };
    const handleCreate = async() => {
        setIsClicked(true);
        const validationErrors = {};
        if (!stateFormData.client_name) {
            validationErrors.client_name = 'Please fill this field';
        }
        if (stateFormData.client_description?.length < 5) {
            validationErrors.client_description = "Input must be at least 5 characters long";
        }
        if (!stateFormData.client_description) {
            validationErrors.client_description = 'Please fill this field';
        }
        if (!stateFormData.image_url) {
            validationErrors.image_url = 'Please fill this field';
        }
        setIsValidate(validationErrors);
        if (Object.keys(validationErrors).length > 0) {
            return;
        }

        const config = {
            headers: {
                "content-type": "multipart/form-data",
                "x-auth-token": token
            }
        };
        const api = "/client/clientCreation";
        try {
            const response = await axiosInstance.post(api, stateFormData, config);
            console.log("clients creation response DATA", response.data);
            clientGetApi();
            toggle();
            setFormData({});
            // Swal.fire({
            //     icon: 'success',
            //     title: 'Success',
            //     text: 'clients added successfully!',
            //     confirmButtonColor: '#3085d6',
            //     confirmButtonText: 'OK'
            // });
        } catch (error) {
            console.log("clients creation Error from API", error);
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Error',
            //     text: 'clients is not created.',
            //     confirmButtonColor: '#d33',
            //     confirmButtonText: 'OK'
            // });
        }
    };
    
    const clientGetApi = async() => await axiosInstance.get("/client/getclients").then(response => {
        setClientsResData(response.data.data);
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = response.data?.data?.slice(indexOfFirstItem, indexOfLastItem);
        setTableData(currentItems);
    }).catch(error => {
        console.log("Error from API", error);
    })
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        const indexOfLastItem = pageNumber * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = clientsResData?.slice(indexOfFirstItem, indexOfLastItem);
        setTableData(currentItems);
    };
    
    const handleEdit = async () => {
        setIsClicked(true);
        const validationErrors = {};

        const validationRules = {
            client_name: { required: true, minLength: 5 },
            client_description: { required: true, minLength: 5 },
            image_url: { required: true }
        };
        
        for (const [fieldName, rules] of Object.entries(validationRules)) {
            const value = stateFormData[fieldName] || selectedRow[fieldName];
            if (rules.required && (!value || value === "")) {
                validationErrors[fieldName] = 'Please fill this field';
            } else if (rules.minLength && value && value.length < rules.minLength) {
                validationErrors[fieldName] = `Please fill this field atleast ${rules.minLength} characters long`;
            }
        }

        setIsValidate(validationErrors);

        // Check if there are any validation errors
        if (Object.keys(validationErrors).length > 0) {
            return; // Stop further execution if there are validation errors
        }
        const formData = new FormData();
        formData.append("client_code", selectedRow.client_code);
        formData.append("client_name", stateFormData.client_name || selectedRow.client_name);
        formData.append("client_description", stateFormData.client_description || selectedRow.client_description);
        formData.append("image_url", stateFormData.image_url || ""); // Assuming image_url is the key for the file
        
        try {
            console.log("response api",formData)
            const response = await axiosInstance.post("/client/clientUpdate", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "x-auth-token": token
                }
            });
            const updatedImageUrl = response.data.image_url;
            setUpdatedImageUrl(updatedImageUrl);
            console.log("Edit service response:", response.data);
            clientGetApi();
            closeEditModal();
            // Swal.fire({
            //     icon: 'success',
            //     title: 'Success',
            //     text: 'Client Updated successfully!',
            //     confirmButtonColor: '#3085d6',
            //     confirmButtonText: 'OK'
            // });
        } catch (error) {
            console.error("Error updating service:", error);
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Error',
            //     text: 'Failed to update Client',
            //     confirmButtonColor: '#d33',
            //     confirmButtonText: 'OK'
            // });
        }
    };

    const handleDelete = async () => {
        try {
            const response = await axiosInstance.post("/client/deleteclient", { client_code: selectedRow.client_code },{
                headers:{
                    'Content-Type': 'application/json',
                    'accept':'application/json',
                    'x-auth-token': token
                }
            }
            );
            console.log("Delete clients response:", response.data);
            clientGetApi();
            closeDeleteModal();
            // Swal.fire({
            //     icon: 'success',
            //     title: 'Success',
            //     text: 'Client deleted successfully!',
            //     confirmButtonColor: '#3085d6',
            //     confirmButtonText: 'OK'
            // });
        } catch (error) {
            console.log("Error deleting clients:", error);
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Error',
            //     text: 'Client is not Deleted',
            //     confirmButtonColor: '#d33',
            //     confirmButtonText: 'OK'
            // });
        }
    };
    return (
        <>
            {/* <Header /> */}
            {/* Page content */}
            <Container className="mt-5" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="bg-transparent d-flex justify-content-between">
                                <h2 className="mb-0">Clients</h2>
                                <Button color="primary" onClick={toggle}>Add</Button>
                            </CardHeader>
                            <CardBody>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            {columns.map((column, index) => (
                                                <th key={index}>{column}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.map((row, rowIndex) => (
                                            <tr key={rowIndex}>
                                                {column.map((column, columnIndex) => (
                                                    <td key={columnIndex}>
                                                        {column === 'action' ? (
                                                            <>
                                                                <button className="btn btn-primary" onClick={() => openEditModal(row)}>
                                                                    edit
                                                                </button>
                                                                <button className="btn btn-default" onClick={() => openDeleteModal(row)}>
                                                                    delete
                                                                </button>
                                                            </>
                                                        ) :
                                                            column === 'image_url' ? (
                                                                <>
                                                                <MyS3Component key={row['client_code']} imageUrl={row['image_url']} />
                                                                </>
                                                            ) : column === 'status' ? (
                                                                <>
                                                                    <CustomInput type="switch" id={`customSwitch${rowIndex}${columnIndex}`} />
                                                                </>
                                                            ) : (
                                                                row[column]
                                                            )}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                {
                                    clientsResData.length > itemsPerPage && (
                                        <ReactPaginate
                                            activePage={currentPage}
                                            itemsCountPerPage={itemsPerPage}
                                            totalItemsCount={clientsResData.length}
                                            pageRangeDisplayed={5}
                                            onChange={handlePageChange}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            innerClass="pagination justify-content-center"
                                        />
                                    )
                                }
                            </CardBody>
                        </Card>
                    </div>
                </Row>
                <Modal isOpen={modal} toggle={toggle} centered size="lg">
                    <ModalHeader toggle={toggle} tag={modalHeaderTag}>Add Clients</ModalHeader>
                    <ModalBody className="bg-secondary">
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Name"
                                    >
                                        Client Name
                                    </label>
                                    <Input
                                        className={`form-control-alternative ${
                                            isValidate.client_name ? (isValidate.client_name?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        defaultValue=""
                                        id="Name"
                                        placeholder="Please Enter Name"
                                        type="text"
                                        name="client_name"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, client_name: true })}
                                        required
                                        aria-describedby="name-error"
                                        onKeyDown={(e) => {
                                            if (e.target.value === '' && !isNaN(parseInt(e.key))) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                     {isClicked && isValidate.client_name && isValidate.client_name?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.client_name}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Branch"
                                    >
                                        Client Description
                                    </label>
                                    <Input
                                        className={`form-control-alternative ${
                                            isValidate.client_description ? (isValidate.client_description?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        defaultValue=""
                                        id="Branch"
                                        placeholder="Please Enter Branch"
                                        type="text"
                                        name="client_description"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, client_description: true })}
                                        required
                                        aria-describedby="name-error"
                                    />
                                     {isClicked && isValidate.client_description && isValidate.client_description?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.client_description}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="12">
                                <FormGroup>
                                    <label for="serviceimage">Client Image</label>
                                    <Input type="file" id="serviceimage" name="image_url"  onChange={(e) => handleInputChange(e.target.name, e.target.files[0])}
                                    accept=".png, .jpg, .jpeg" onBlur={() => setIsValidate({ ...isValidate, productName: true })}
                                        required
                                        aria-describedby="name-error" />
                                    <FormText color="muted">
                                    Please select .png or .jpg images only within 1024x768px.
                                    </FormText>
                                    {isClicked && isValidate.image_url && isValidate.image_url?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.image_url}</small>
                                )}
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleCreate}>
                            Add
                        </Button>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* Edit Modal */}
                <Modal isOpen={isEditModalOpen} toggle={closeEditModal} centered size="lg">
                    <ModalHeader toggle={closeEditModal} tag={modalHeaderTag}>Edit Leadership</ModalHeader>
                    <ModalBody className="bg-secondary">
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Name"
                                    >
                                        Client Name
                                    </label>
                                    <Input
                                        className={`form-control-alternative ${
                                            isValidate.client_name ? (isValidate.client_name?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        defaultValue={selectedClientData.client_name || ""}
                                        id="Name"
                                        placeholder="Please Enter Name"
                                        type="text"
                                        name="client_name"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.target.value === '' && !isNaN(parseInt(e.key))) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                     {isClicked && isValidate.client_name && isValidate.client_name?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.client_name}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Branch"
                                    >
                                        Client Description
                                    </label>
                                    <Input
                                        className={`form-control-alternative ${
                                            isValidate.client_description ? (isValidate.client_description?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        defaultValue={selectedClientData.client_description || ""}
                                        id="Branch"
                                        placeholder="Please Enter Branch"
                                        type="text"
                                        name="client_description"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                       
                                    />
                                     {isClicked && isValidate.client_description && isValidate.client_description?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.client_description}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="12">
                                <FormGroup>
                                    <label for="serviceimage">Client Image</label>
                                    <Input type="file" id="serviceimage" name="image_url"  onChange={(e) => handleInputChange(e.target.name, e.target.files[0])}
                                    accept=".png, .jpg, .jpeg"x />
                                    <FormText color="muted">
                                    Please select .png or .jpg images only within 1024x768px.
                                    </FormText>
                                    {isImageVisible && (updatedImageUrl || selectedClientData.image_url) ? (
                                        <div style={{ position: 'relative', display: 'inline-block' }}>
                                            <MyS3Component key={selectedClientData?.client_code} imageUrl={selectedClientData?.image_url ? selectedClientData?.image_url : updatedImageUrl} />
                                            <Button size="sm" style={{ position: 'absolute', top: 0, right: "-40px" }} onClick={handleRemoveImage}>
                                                X
                                            </Button>
                                        </div>
                                    ) : null}
                                    {isClicked && isValidate.image_url && isValidate.image_url?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.image_url}</small>
                                )}
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleEdit}>
                            Save
                        </Button>{" "}
                        <Button color="secondary" onClick={closeEditModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* Delete Modal */}
                <Modal isOpen={isDeleteModalOpen} toggle={closeDeleteModal} centered size="lg">
                <ModalHeader toggle={closeDeleteModal} tag={modalHeaderTag}>Delete</ModalHeader>
                <ModalBody className="bg-secondary">
                    Are you sure you want to delete this Client?
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={handleDelete}>
                        Yes
                    </Button>{" "}
                    <Button color="secondary" onClick={closeDeleteModal}>
                        No
                    </Button>
                </ModalFooter>
            </Modal>
            </Container>
        </>
    );
};

export default Clients;
