
import { useState, useEffect } from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
import MyS3Component from "../../components/s3/s3";
import Swal from 'sweetalert2';
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Input,
    Table, Alert,
    Label,
    FormText,
    CustomInput
} from "reactstrap";
import ReactPaginate from 'react-js-pagination';
import axiosInstance from '../../axiosInterceptor';

const headers = ['Title', 'Image', 'Subject', 'Description', 'Action'];
const columns = ['image_title', 'image_url', 'serviceSubject', 'image_description', 'action'];

const Services = () => {
    const itemsPerPage = 5; // Number of items to display per page
    const [modal, setModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null)
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [stateFormData, setFormData] = useState({});
    const [servicesResData, setServicesResData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [isClicked, setIsClicked] = useState(false);
    const [selectedServiceData, setSelectedServiceData] = useState({});
    const [isImageVisible, setIsImageVisible] = useState(true);

    const [updatedImageUrl,setUpdatedImageUrl]=useState({})
    // const [isValidate, setIsValidate] = useState({
    //     image_title: false,
    // });
    console.log("stateFormData",stateFormData)
    const [isValidate, setIsValidate] = useState({ image_title: false, image_description: false,serviceSubject:false, image_url: false, })
    useEffect(() => {
        servicesGetApi()
    }, []);
    const token = localStorage.getItem('JWT');
    const handleRemoveImage = () => {
        
        setIsImageVisible(false);
    };
    const toggle = () => setModal(!modal);
    const toggleSuccessModal = () => setSuccessModal(!successModal);

    const servicesGetApi = async () => await axiosInstance.get("/services/getServices").then(response => {
        setServicesResData(response.data.data);
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = response.data?.data?.slice(indexOfFirstItem, indexOfLastItem);
        setTableData(currentItems);
    }).catch(error => {
        console.log("Error from API", error);
    })

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        const indexOfLastItem = pageNumber * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = servicesResData?.slice(indexOfFirstItem, indexOfLastItem);
        setTableData(currentItems);
    };
    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        setSelectedImage(imageFile);
    };
    const modalHeaderTag = 'h2';
    const openEditModal = async (row) => {
        setSelectedRow(row);
        setEditModalOpen(true);
        
        // Fetch service data by code
        try {
            const response = await axiosInstance.get(`/services/getServicesbyCode?serviceCode=${row.serviceCode}`);
            setSelectedServiceData(response.data.data);
        } catch (error) {
            console.log("Error fetching service data by code:", error);
        }
    };
    const openDeleteModal = (row) => {
        setSelectedRow(row);
        setDeleteModalOpen(true);
    };
    const closeEditModal = () => {
        setEditModalOpen(false);
    };
    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
    };

    const handleCreate = async () => {
        setIsClicked(true);
    
        // Validation logic
        const validationErrors = {};
        if (stateFormData.image_title?.length < 5) {
            validationErrors.image_title = 'Input must be at least 5 characters long';
        }
        if (!stateFormData.image_title) {
            validationErrors.image_title = 'Please fill this field';
        }
        if (stateFormData.image_description?.length < 5) {
            validationErrors.image_description = 'Input must be at least 5 characters long';
        }
        if (!stateFormData.image_description) {
            validationErrors.image_description = 'Please fill this field';
        }
        if (!stateFormData.serviceSubject) {
            validationErrors.serviceSubject = 'Please fill this field';
        } else if (stateFormData.serviceSubject.length < 5) {
            validationErrors.serviceSubject = 'Input must be at least 5 characters long';
        } else if (stateFormData.serviceSubject.length > 109) { 
            validationErrors.serviceSubject = 'Input must be at most 109 characters long';
        }
        
        if (!stateFormData.image_url) {
            validationErrors.image_url = 'Please fill this field';
        }
    
        setIsValidate(validationErrors);
    
        // Check if there are any validation errors
        if (Object.keys(validationErrors)?.length > 0) {
            return;
        }
    
        // Proceed with API call
        const config = {
            headers: {
                "content-type": "multipart/form-data",
                "x-auth-token": token
            }
        };
        const api = "services/serviceCreation ";
        try {
            const response = await axiosInstance.post(api, stateFormData, config);
            console.log("services creation response DATA", response.data);
            servicesGetApi();
            toggle();
            setFormData({});
    
            // Display success message using SweetAlert
            // Swal.fire({
            //     icon: 'success',
            //     title: 'Success',
            //     text: 'Service added successfully!',
            //     confirmButtonColor: '#3085d6',
            //     confirmButtonText: 'OK'
            // });
        } catch (error) {
            console.log("services creation Error from API", error);
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Error',
            //     text: 'Service is not created.',
            //     confirmButtonColor: '#d33',
            //     confirmButtonText: 'OK'
            // });
        }
    };
    

    const handleEdit = async () => {
        setIsClicked(true);
        const validationErrors = {};

        const validationRules = {
            image_title: { required: true, minLength: 5 },
            image_description: { required: true, minLength: 5 },
            serviceSubject: { required: true, minLength: 5 },
            link: { required: true, minLength: 5 },
            image_url: { required: true }
        };
        
        for (const [fieldName, rules] of Object.entries(validationRules)) {
            const value = stateFormData[fieldName] || selectedRow[fieldName];
            if (rules.required && (!value || value === "")) {
                validationErrors[fieldName] = 'Please fill this field';
            } else if (rules.minLength && value && value.length < rules.minLength) {
                validationErrors[fieldName] = `Please fill this field atleast ${rules.minLength} characters long`;
            }
        }

        setIsValidate(validationErrors);

        // Check if there are any validation errors
        if (Object.keys(validationErrors).length > 0) {
            return; // Stop further execution if there are validation errors
        }

        const formData = new FormData();
        formData.append("serviceCode", selectedRow.serviceCode);
        formData.append("image_title", stateFormData.image_title || selectedRow.image_title);
        formData.append("image_description", stateFormData.image_description || selectedRow.image_description);
        formData.append("link", stateFormData.link || selectedRow.link);
        formData.append("serviceSubject", stateFormData.serviceSubject || selectedRow.serviceSubject);
        formData.append("image_url", stateFormData.image_url || ""); // Assuming image_url is the key for the file
        
        try {
            console.log("response api",formData)
            const response = await axiosInstance.post("/services/serviceUpdate", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "x-auth-token": token
                }
            });
            const updatedImageUrl = response.data.image_url;
            setUpdatedImageUrl(updatedImageUrl);
            console.log("Edit service response:", response.data);
            servicesGetApi();
            closeEditModal();
            // Swal.fire({
            //     icon: 'success',
            //     title: 'Success',
            //     text: 'Service Updated successfully!',
            //     confirmButtonColor: '#3085d6',
            //     confirmButtonText: 'OK'
            // });
        } catch (error) {
            console.error("Error updating service:", error);
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Error',
            //     text: 'Failed to update service',
            //     confirmButtonColor: '#d33',
            //     confirmButtonText: 'OK'
            // });
        }
    };
    
    

    const handleDelete = async () => {
        try {
            // const config = {
            //     headers: {
            //         "x-auth-token": token
            //     }
            // };
            const response = await axiosInstance.post("/services/deleteService", { serviceCode: selectedRow.serviceCode }, {
                headers:{
                    'Content-Type': 'application/json',
                    'accept':'application/json',
                    'x-auth-token': token
                }
            });
            console.log("Delete service response:", response.data);
            servicesGetApi();
            closeDeleteModal();
            // Swal.fire({
            //     icon: 'success',
            //     title: 'Success',
            //     text: 'Service deleted successfully!',
            //     confirmButtonColor: '#3085d6',
            //     confirmButtonText: 'OK'
            // });
        } catch (error) {
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Error',
            //     text: 'Service is not Deleted',
            //     confirmButtonColor: '#d33',
            //     confirmButtonText: 'OK'
            // });
            console.log("Error deleting service:", error);
        }
    };
    const handleFileInputChange = (inputName, val) => {
        const file = val
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    
        if (file && !allowedTypes.includes(file.type)) {
            alert('Please select a valid image file (PNG or JPG).');
            handleInputChange(inputName, file);
        }
    };
    

    const handleInputChange = (inputName, val) => {
        console.log("Event data change", inputName, "   val", val);
        if(inputName ==='image_url'){
            const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            if (val && !allowedTypes.includes(val.type)) {
                alert('Please select a valid image file (PNG or JPG).');
            }
            setFormData((prevData) => {
                return {
                    ...prevData,
                    [inputName]: val,
                }
            })
            setIsValidate((prevValidation) => ({
                ...prevValidation,
                [inputName]: !!val,
            }));

        }
        setFormData((prevData) => {
            return {
                ...prevData,
                [inputName]: val,
            }
        })
        setIsValidate((prevValidation) => ({
            ...prevValidation,
            [inputName]: !!val,
        }));
    };
    

    return (
        <>
            <Container className="mt-5" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="bg-transparent d-flex justify-content-between">
                                <h2 className="mb-0">Services</h2>
                                <Button color="primary" onClick={toggle}>Add</Button>
                            </CardHeader>
                            <CardBody>
                                <Table className="align-items-center table-flush" responsive sorting={true}>
                                    <thead className="thead-light">
                                        <tr>
                                            {headers.map((column, index) => (
                                                <th key={index}>{column}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.map((row, rowIndex) => (
                                            <tr key={rowIndex}>
                                                {columns.map((column, columnIndex) => (
                                                    <td key={columnIndex}>
                                                        {column === 'action' ? (
                                                            <>
                                                                <button className="btn btn-primary" onClick={() => openEditModal(row)}>
                                                                    edit
                                                                </button>
                                                                <button className="btn btn-default" onClick={() => openDeleteModal(row)}>
                                                                    delete
                                                                </button>
                                                            </>
                                                        ) :
                                                            column === 'image_url' ? (
                                                                // <MyS3Component imageURL={row['image_url']} style={{ display: 'block', width: 60, height: 40 }} />
                                                                <MyS3Component key={row['serviceCode']} imageUrl={row['image_url']} />
                                                            ) :
                                                                column === "serviceStatus" ? (
                                                                    <>
                                                                        <CustomInput type="switch" id={`customSwitch${rowIndex}`} checked={row['serviceStatus'] === 'ACTIVE'} />
                                                                    </>
                                                                ) :
                                                                    (
                                                                        row[column]
                                                                    )}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                {
                                    servicesResData.length > itemsPerPage && (
                                        <ReactPaginate
                                            activePage={currentPage}
                                            itemsCountPerPage={itemsPerPage}
                                            totalItemsCount={servicesResData.length}
                                            pageRangeDisplayed={5}
                                            onChange={handlePageChange}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            innerClass="pagination justify-content-center"
                                        />
                                    )
                                }
                            </CardBody>
                        </Card>
                    </div>
                </Row>
                <Modal isOpen={modal} toggle={toggle} centered size="lg">
                    <ModalHeader toggle={toggle} tag={modalHeaderTag}>Add Services</ModalHeader>
                    <ModalBody className="bg-secondary">
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Name"
                                    >
                                        Title
                                    </label>
                                    <Input
                                    className={`form-control-alternative ${
                                        isValidate.image_title ? (isValidate.image_title?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                    }`}
                                    defaultValue=""
                                    id="Name"
                                    placeholder="Please Enter Name"
                                    type="text"
                                    name="image_title"
                                    onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                    onBlur={() => setIsValidate({ ...isValidate, image_title: true })}
                                    required
                                    aria-describedby="name-error"
                                    
                                />
                               {isClicked && isValidate.image_title && isValidate.image_title?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.image_title}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Branch"
                                    >
                                        DESC
                                    </label>
                                    <Input
                                         className={`form-control-alternative ${
                                            isValidate.image_description ? (isValidate.image_description.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        defaultValue=""
                                        id="Branch"
                                        placeholder="Please Enter Branch"
                                        type="text"
                                        name="image_description"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, image_description: true })}
                                        required
                                        aria-describedby="name-error"
                                    />
                                    {isClicked && isValidate.image_description && isValidate.image_description?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.image_description}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Branch"
                                    >
                                        subject
                                    </label>
                                    <Input
                                         className={`form-control-alternative ${
                                            isValidate.serviceSubject ? (isValidate.serviceSubject?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        defaultValue=""
                                        id="Branch"
                                        placeholder="Please Enter Branch"
                                        type="text"
                                        name="serviceSubject"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        required
                                        onBlur={() => setIsValidate({ ...isValidate, serviceSubject: true })}
                                        aria-describedby="name-error"
                                        maxLength={109} 
                                    />
                                    {isClicked && isValidate.serviceSubject && isValidate.serviceSubject.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.serviceSubject}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="12">
                                <FormGroup>
                                    <Label for="serviceimage">File</Label>
                                    <Input type="file" id="sehandleCreaterviceimage" name="image_url"onBlur={() => setIsValidate({ ...isValidate, image_url: true })} onChange={(e) => handleInputChange(e.target.name, e.target.files[0])}   required aria-describedby="name-error" accept=".png, .jpg, .jpeg"/>
                                    <FormText color="muted">
                                    Please select .png or .jpg images only within 1024x768px.
                                    </FormText>
                                    {isClicked && isValidate.image_url && (!stateFormData.image_url&&
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.image_url}</small>
                                )}
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleCreate}>
                            Add
                        </Button>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* Success Modal */}
                <Modal isOpen={successModal} toggle={toggleSuccessModal} centered>
                    <ModalBody>
                        <Alert color="success">Services added successfully!</Alert>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={toggleSuccessModal}>
                            OK
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* Edit Modal */}
                <Modal isOpen={isEditModalOpen} toggle={closeEditModal} centered size="lg">
                    <ModalHeader toggle={closeEditModal} tag={modalHeaderTag}>Edit Row</ModalHeader>
                    <ModalBody className="bg-secondary">
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="image_title"
                                    >
                                        Service Name
                                    </label>
                                    <Input
                                        className={`form-control-alternative ${
                                            isValidate.image_title ? (isValidate.image_title.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        id="image_title"
                                        placeholder="Please Enter Name"
                                        type="text"
                                        name="image_title"
                                        defaultValue={selectedServiceData.image_title || ""}
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                    />
                                    {isClicked && isValidate.image_title && isValidate.image_title?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.image_title}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="serviceSubject"
                                    >
                                        Service Subject
                                    </label>
                                    <Input
                                        className={`form-control-alternative ${
                                            isValidate.serviceSubject ? (isValidate.serviceSubject.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        id="serviceSubject"
                                        placeholder="Please Enter subject"
                                        type="text"
                                        name="serviceSubject"
                                        defaultValue={selectedServiceData.serviceSubject || ""}
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        maxLength={109}
                                    />
                                    {isClicked && isValidate.serviceSubject && isValidate.serviceSubject?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.serviceSubject}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="image_description"
                                    >
                                        Service Description
                                    </label>
                                    <Input
                                         className={`form-control-alternative ${
                                            isValidate.image_description ? (isValidate.image_description.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        id="image_description"
                                        rows={6}
                                        type="textarea"
                                        style={{ width: "100%" }}
                                        defaultValue={selectedServiceData.image_description || ""}
                                        placeholder="Please Enter description"
                                        name="image_description"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                    />
                                     {isClicked && isValidate.image_description && isValidate.image_description?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.image_description}</small>
                                )}
                                </FormGroup>
                            </Col>
                            {/* <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Branch"
                                    >
                                        Image Title
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        defaultValue=""
                                        id="Branch"
                                        placeholder="Please Enter Title "
                                        type="text"
                                        name="branch"
                                        value={selectedServiceData.image_url || ""}
                                        onChange={(e) => handleInputChange(e.target)}
                                    />
                                </FormGroup>
                            </Col> */}
                            <Col lg="6">
                                <FormGroup>
                                    <Label for="serviceimage">File</Label>
                                    <Input
                                        type="file"
                                        id="sehandleCreaterviceimage"
                                        name="image_url"
                                        onBlur={() => setIsValidate({ ...isValidate, image_url: true })}
                                        onChange={(e) => handleInputChange(e.target.name, e.target.files[0])}
                                        accept=".png, .jpg, .jpeg"
                                        required
                                        aria-describedby="name-error"
                                        maxLength={109} 
                                    />
                                    <FormText color="muted">
                                    Please select .png or .jpg images only within 1024x768px.
                                    </FormText>
                                    {isImageVisible && (updatedImageUrl || selectedServiceData.image_url) ? (
                                        <div style={{ position: 'relative', display: 'inline-block' }}>
                                            <MyS3Component key={selectedServiceData?.serviceCode} imageUrl={selectedServiceData?.image_url ? selectedServiceData?.image_url : updatedImageUrl} />
                                            <Button size="sm" style={{ position: 'absolute', top: 0, right: "-40px" }} onClick={handleRemoveImage}>
                                                X
                                            </Button>
                                        </div>
                                    ) : null}
                                     {isClicked && isValidate.image_url && isValidate.image_url?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.image_url}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="link"
                                    >
                                       link
                                    </label>
                                    <Input
                                        className={`form-control-alternative ${
                                            isValidate.link ? (isValidate.link.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        id="link"
                                        placeholder="Please Enter Link"
                                        type="text"
                                        name="link"
                                        defaultValue={selectedServiceData.link || ""}
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                    />
                                     {isClicked && isValidate.link && isValidate.link?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.link}</small>
                                )}
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleEdit}>
                            Save
                        </Button>{" "}
                        <Button color="secondary" onClick={closeEditModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* Delete Modal */}
                <Modal isOpen={isDeleteModalOpen} toggle={closeDeleteModal} centered size="lg">
                    <ModalHeader toggle={closeDeleteModal} tag={modalHeaderTag}>Delete</ModalHeader>
                    <ModalBody className="bg-secondary">
                        Are you sure you want to delete this service?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={handleDelete}>
                            Yes
                        </Button>{" "}
                        <Button color="secondary" onClick={closeDeleteModal}>
                            No
                        </Button>
                    </ModalFooter>
                </Modal>
            </Container>
        </>
    );
};

export default Services;
