import { useState } from "react";
import {
    Table,
    Input,
    FormGroup,
    Form,
    Label,
    CustomInput
} from "reactstrap";
// core components
import ReactPaginate from 'react-js-pagination';
import { FontAwesomeIcon, } from '@fortawesome/fontawesome-free';
//import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
//import { config } from '@fortawesome/fontawesome-svg-core';



const CTable = ({ data = [], columns = [] }) => {
    const itemsPerPage = 5; // Number of items to display per page
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    console.log("data", data, columns);
    return (
        <>
            <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                    <tr>
                        {columns.map((column, index) => (
                            <th key={index}>{column}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {columns.map((column, columnIndex) => (
                                <td key={columnIndex}>
                                    {column === 'action' ? (
                                        <>
                                            <button className="btn btn-primary">
                                                edit
                                            </button>
                                            <button className="btn btn-default">
                                                delete
                                            </button>
                                        </>
                                    ) :
                                        column === 'icons' ? (
                                            <></>
                                        ) : column === 'status' ? (
                                            <>
                                                <CustomInput type="switch" id={`customSwitch${rowIndex}${columnIndex}`} />
                                            </>
                                        ) : (
                                            row[column]
                                        )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
            {
                data.length > itemsPerPage && (
                    <ReactPaginate
                        activePage={currentPage}
                        itemsCountPerPage={itemsPerPage}
                        totalItemsCount={data.length}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass="pagination justify-content-center"
                    />
                )
            }
        </>
    );
};

export default CTable;
