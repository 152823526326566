import { useState, useEffect } from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    CustomInput,
    UncontrolledTooltip,
    Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Table, Alert,FormText
} from "reactstrap";
import ReactPaginate from 'react-js-pagination';
import axiosInstance from '../../axiosInterceptor';
import MyS3Component from "../../components/s3/s3";
import Swal from "sweetalert2";
import { Editor } from "primereact/editor";

// core components
// import Header from "components/Headers/Header.js";
//import Table from "../../components/Table/Table";

const data = [
    { id: 1, icons: 'John Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 1', action: 'edit,delete', status: '' },
    { id: 2, icons: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 2', action: 'edit,delete', status: 'inactive' },
    { id: 3, icons: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 3', action: 'edit,delete', status: 'active' },
    { id: 4, icons: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 4', action: 'edit,delete', status: 'inactive' },
    { id: 5, icons: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 5', action: 'edit,delete', status: 'active' },
    // Add more data as needed
];

// const columns = ['S.No', 'Service Name', 'Subject', 'Description', 'status', 'action'];

// const columns = ['image_title', 'image_url', 'Blogubject', 'Blogtatus', 'link','status','action'];
const columns = [ 'BLOG NAME', 'BLOG IMAGE','BLOG TYPE', 'BLOG SUBJECT',  'ACTION'];
const column =['blogName','blogThumbnail','blog_type','blogSubject'];
const initialBlogData = [{
        bb_story_title: '',
        bb_story_description: ''
    }]


const Blog = () => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const itemsPerPage = 5; // Number of items to display per page
    const [currentPage, setCurrentPage] = useState(1);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    console.log("selectedRow data", selectedRow);
    const [successModal, setSuccessModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    // const [formData, setFormData] = useState({});
    const [BlogResData, SetBlogData] = useState([]);
    const toggleSuccessModal = () => setSuccessModal(!successModal);
    const [stateFormData, setFormData] = useState({});
    console.log("formData is", stateFormData);
    const [isClicked, setIsClicked] = useState(false);
    const[selectedBlogData,setSelectedBlogData]=useState({});
    const [isValidate, setIsValidate] = useState({ blogName: false, blogDescription: false,blog_type:false,blogSubject:false,blogContent:false,image_title:false,blogThumbnail:false,})
    const [updatedBlogThumbnail,setupdatedBlogThumbnail]=useState({})
    const [isImageVisible, setIsImageVisible] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [blogType, setBlogType] = useState('text');
  const [additionalBlogs, setAdditionalBlogs] = useState([]);

    
    const handleAddMore = () => {
        setAdditionalBlogs([...additionalBlogs, "editor"]);
      };
    
    useEffect(() => {
        BlogGetApi()
        console.log("BlogData", BlogResData)
    }, []);
    const handleRemoveImage = () => {
       
        setIsImageVisible(false);
    };
    const token = localStorage.getItem('JWT');
    console.log("selectedBlogData",selectedBlogData)
    const BlogGetApi = () => axiosInstance.get("/blog/blogRetrieve").then(response => {
        console.log("Blog response DATA", response.data);
        SetBlogData(response.data.data);
        const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = response.data?.data?.slice(indexOfFirstItem, indexOfLastItem);
    setTableData(currentItems);

    }).catch(error => {
        console.log("Error from API", error);
    })
    // const indexOfLastItem = currentPage * itemsPerPage;
    // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    // const currentItems = (BlogData && BlogData.data) ? BlogData.data.slice(indexOfFirstItem, indexOfLastItem) : [];
    // console.log("data", data, columns);
    const modalHeaderTag = 'h2';
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = BlogResData?.slice(indexOfFirstItem, indexOfLastItem);
    setTableData(currentItems);
    };
    const openEditModal = async (row) => {
        setSelectedRow(row);
        setEditModalOpen(true);
        // try{
            
        //     const response = await axiosInstance.get(`/blog/blogRetrieve?blog_code=${row.blogCode}`);
        //     console.log("sdddddddd",response)
        //     setSelectedBlogData(response.data.data[0]);
        // }catch (error) {
        //     console.log("Error fetching blog data by code:",error);
        // }
    };
    
    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        setSelectedImage(imageFile);
    };
    const openDeleteModal = (row) => {
        setSelectedRow(row);
        setDeleteModalOpen(true);
    };

    const closeEditModal = () => {
        setEditModalOpen(false);
    };
    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
    };
    
 
    const handleCreate = async() => {
        setIsClicked(true);
        const config = {
            headers: {
                "content-type": "multipart/form-data",
                "x-auth-token": token
            }
        };
        const api = "/blog/blogCreation";
        try{
        console.log("stateFormData",stateFormData)
        const response=await axiosInstance.post(api, stateFormData, config);
        console.log("Blog creation response DATA",response.data)
           BlogGetApi();
            toggle();
            setFormData({});
            // Swal.fire({
            //     icon: 'success',
            //     title: 'Success',
            //     text: 'Blog added successfully!',
            //     confirmButtonColor: '#3085d6',
            //     confirmButtonText: 'OK' 
            // });
        } catch(error) {
            console.log("Blog creation Error from API", error);
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Error',
            //     text: 'Blog is not created.',
            //     confirmButtonColor: '#d33',
            //     confirmButtonText: 'OK'
            // });
        }
    };

    const handleEdit = async () => {
        // const formData= new FormData();
        // formData.append("blogCode",selectedRow.blogCode);
        // formData.append("blogName",stateFormData.blogName||selectedRow.blogName);
        // formData.append("blogSubject",stateFormData.blogSubject||selectedRow.blogSubject);
        // formData.append("blogContent",stateFormData.blogContent||selectedRow.blogContent);
        // formData.append("blog_type",stateFormData.blog_type||selectedRow.blog_type);
        // formData.append("blogDescription",stateFormData.blogDescription||selectedRow.blogDescription);
        // formData.append("image_title",stateFormData.image_title||selectedRow.image_title);
        // formData.append("blogThumbnail",stateFormData.blogThumbnail||"");
        // formData.append()
        try{
            const response = await axiosInstance.post("/blog/blogUpdate",selectedRow,{
                headers: {
                    "content-type": "multipart/form-data",
                    "x-auth-token": token
                }
            });
            const updatedBlogThumbnail = response.data.blogThumbnail;
            setupdatedBlogThumbnail(updatedBlogThumbnail);
            console.log("Edit Blog response:", response.data);
            BlogGetApi();
            closeEditModal();
            // Swal.fire({
            //     icon:'success',
            //     title:"Success",
            //     text:'Blog updated successfully!',
            //     confirmButtonColor:"#3085d6",
            //     confirmButtonText:'OK' 
            // });
        }catch(error){
            // Swal.fire({
            //     icon:'error',
            //     title:'Error',
            //     text:'failed to update Blog',
            //     confirmButtonColor:'#d33',
            //     confirmButtonText:'OK'
            // });
        }
    
    };

    const handleDelete = async () => {
        try {
            // Call the delete blog API with the blog code
            const response = await axiosInstance.post("/blog/blogDelete", { blogCode: selectedRow.blogCode });
    
            // Handle the success response
            console.log("Delete blog response:", response.data);
    
            // Optionally, you can refresh the blogs data after deletion
            BlogGetApi();
    
            // Close the delete modal
            closeDeleteModal();
            // Swal.fire({
            //     icon: 'success',
            //     title: 'Success',
            //     text: 'Blog deleted successfully!',
            //     confirmButtonColor: '#3085d6',
            //     confirmButtonText: 'OK'
            // });
        } catch (error) {
            // Handle the error
            console.log("Error deleting blog:", error);
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Error',
            //     text: 'Blog is not Deleted',
            //     confirmButtonColor: '#d33',
            //     confirmButtonText: 'OK'
            // });
        }
    };

    // const handleTitleChange = (e) => {
    //     const value = e.target.value;
    //     setTitle(value);

    //     // Basic validation example: Title should not be empty
    //     if (value.trim() === '') {
    //         setTitleError('Title is required');
    //     } else {
    //         setTitleError(null);
    //     }
    // };
    // const handleAddBlog = () => {
    //     setFormData((prevData) => {
    //       const newBlog = {
    //         BlogTitle: '',
    //         descriptionType: 'text',
    //         BlogDescription: '',
    //         bullets: [''],
    //       };
      
    //       return {
    //         ...prevData,
    //         Blog: prevData.Blog ? [...prevData.Blog, newBlog] : [newBlog],
    //       };
    //     });
    //   };
    const handleStoryInputChange = (index, field, value) => {
        console.log("index ==========", index, field, value);

        setFormData((prevData) => {
            console.log("prevData state data is", prevData);
            const updatedStories = prevData.blog_list.map((story, idx) => {
                console.log("STORY DATA", story);
                if (String(idx) === String(index)) {
                    console.log("       return ing data", { ...story, [field]: value });
                    return { ...story, [field]: value };
                }
                return story;
            });
            console.log("updated story", updatedStories);
            return {
                ...prevData,
                blog_list: updatedStories,
            }
        });
    };
    const handleEditNewStoryPoint = () => {
        setAdditionalBlogs([...additionalBlogs, "editor"]);
        setFormData((prevData) => {
            console.log("prevData is", prevData);
            return {
                ...prevData,
                blog_list: [...prevData?.blog_list, ...initialBlogData]
            };
        });
    };
      const handleDescriptionTypeChange = (index, value) => {
        setFormData((prevData) => {
          const updatedBlog = [...prevData.Blog];
          updatedBlog[index].descriptionType = value;
          
          // If switching to bullets, add an initial bullet
          if (value === 'bullets') {
            updatedBlog[index].bullets = [''];
          } else {
            updatedBlog[index].bullets = [];
          }
      
          return {
            ...prevData,
            Blog: updatedBlog,
          };
        });
      };
    //   const handleStoryInputChange = (index,field, value) => {
    //     setFormData((prevData) => ({
    //       ...prevData,
    //       [field]: value
    //     }));
    //   };
      
    //   const handleStoryInputChange = (index, field, value) => {
    //     console.log("index ==========", index, field, value);

    //     setFormData((prevData) => {
    //         console.log("prevData state data is", prevData);
    //         const updatedStories = prevData.blog_list.map((story, idx) => {
    //             console.log("STORY DATA", story);
    //             if (String(idx) === String(index)) {
    //                 console.log("       return ing data", { ...story, [field]: value });
    //                 return { ...story, [field]: value };
    //             }
    //             return story;
    //         });
    //         console.log("updated story", updatedStories);
    //         return {
    //             ...prevData,
    //             blog_list: updatedStories,
    //         }
    //     });
    // };
      const handleInputChange = (inputName, val) => {
        console.log("Event data change", inputName, "   val", val);
        setFormData((prevData) => {
            return {
                ...prevData,
                [inputName]: val,
            }
        })
    };
    const handleEditInputChange = (inputName, val) => {
        console.log("Event data change", inputName, "   val", val);
        setSelectedRow((prevData) => {
            return {
                ...prevData,
                [inputName]: val,
            }
        })
    };
    const handleAddNewStoryPoint = () => {
        setAdditionalBlogs([...additionalBlogs, "editor"]);
        setFormData((prevData) => {
            console.log("prevData is", prevData);
            return {
                ...prevData,
                blog_list: [...prevData?.blog_list, ...initialBlogData]
            };
        });
    };
    const handleEditStoryInputChange = (index, field, value) => {
        console.log("index ==========", index, field, value);

        setSelectedRow((prevData) => {
            console.log("prevData state data is", prevData);
            const updatedblog = prevData.blog_list.map((blog, idx) => {
                console.log("blog DATA", blog);
                if (String(idx) === String(index)) {
                    console.log("       return ing data", { ...blog, [field]: value });
                    return { ...blog, [field]: value };
                }
                return blog;
            });
            console.log("updated blog", updatedblog);
            return {
                ...prevData,
                blog_list: updatedblog,
            }
        });
    };
    const handleBlogContent = (type) =>{
        setBlogType(type)
        if(type ==='editor'){
            setFormData({...stateFormData, external_link:'',blog_list:initialBlogData})
        }else{
            setFormData({...stateFormData, external_link:'',blog_list:[]})
        }
    }
    
    return (
        <>
            <Container className="mt-5" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="bg-transparent d-flex justify-content-between">
                                <h2 className="mb-0">Blog</h2>
                                <Button color="primary" onClick={toggle}>Add</Button>
                            </CardHeader>
                            <CardBody>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            {columns.map((column, index) => (
                                                <th key={index}>{column}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.map((column, columnIndex) => (
                                            <tr key={columnIndex}>
                                              
                                                <td>{column['blogName']}</td>
                                                <td><MyS3Component key={column['blogCode']} imageUrl={column['blogThumbnail']} /></td>
                                                <td>{column['blog_type']}</td>
                                                <td>{column['blogSubject']}</td>
                                               

                                                <td>

                                                    <button className="btn btn-primary" onClick={() => openEditModal(column)}>
                                                        edit
                                                    </button>
                                                    <button className="btn btn-default" onClick={() => openDeleteModal(column)}>
                                                        delete
                                                    </button>
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                {
                                    BlogResData.length > itemsPerPage && (
                                        <ReactPaginate
                                            activePage={currentPage}
                                            itemsCountPerPage={itemsPerPage}
                                            totalItemsCount={BlogResData.length}
                                            pageRangeDisplayed={5}
                                            onChange={handlePageChange}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            innerClass="pagination justify-content-center"
                                        />
                                    )
                                }
                            </CardBody>
                        </Card>
                    </div>
                </Row>
                <Modal isOpen={modal} toggle={toggle} centered size="lg">
                    <ModalHeader toggle={toggle} tag={modalHeaderTag}>Add Blog</ModalHeader>
                    <ModalBody className="bg-secondary">
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="blogName"
                                    >
                                        Blog Name
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        defaultValue=""
                                        id="blogName"
                                        placeholder="Please Enter name"
                                        type="text"
                                        name="blogName"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, blogName: true })}
                                        required
                                        aria-describedby="name-error"
                                    />

                                    {isClicked && !isValidate.blogName && (
                                        <small id="name-error" className='text-danger my-0 d-block fz-12px mt-3 fw-100'>Please fill this field</small>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Description"
                                    >
                                        Blog type
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        defaultValue=""
                                        id="Description"
                                        placeholder="Please Enter type"
                                        type="text"
                                        name="blog_type"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, blog_type: true })}
                                        required
                                        aria-describedby="name-error"
                                    />

                                    {isClicked && !isValidate.blog_type && (
                                        <small id="name-error" className='text-danger my-0 d-block fz-12px mt-3 fw-100'>Please fill this field</small>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Description"
                                    >
                                        Blog subject
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        defaultValue=""
                                        id="subject"
                                        rows={6}
                                        placeholder="Please Enter subject"
                                        type="textarea"
                                        name="blogSubject"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                    />
                                    {isClicked && !isValidate.blogSubject && (
                                        <small id="name-error" className='text-danger my-0 d-block fz-12px mt-3 fw-100'>Please fill this field</small>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Description"
                                    >
                                        Blog description
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        defaultValue=""
                                        id="Description"
                                        rows={6}
                                        placeholder="Please Enter Description"
                                        type="textarea"
                                        name="blogDescription"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, blogDescription: true })}
                                        required
                                        aria-describedby="name-error"
                                    />
                                    {isClicked && !isValidate.blogDescription && (
                                        <small id="name-error" className='text-danger my-0 d-block fz-12px mt-3 fw-100'>Please fill this field</small>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Icon"
                                    >
                                        Blog content
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        defaultValue=""
                                        id="Icon"
                                        rows={6}
                                        placeholder="Please enter content"
                                        type="textarea"
                                        name="blogContent"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, blogContent: true })}
                                        required
                                        aria-describedby="name-error"
                                    />
                                    {isClicked && !isValidate.blogContent && (
                                        <small id="name-error" className='text-danger my-0 d-block fz-12px mt-3 fw-100'>Please fill this field</small>
                                    )}
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Title"
                                    >
                                        image Title
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        defaultValue=""
                                        id="Title"
                                        placeholder="Please Enter Title"
                                        type="text"
                                        name="image_title"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, image_title: true })}
                                        required
                                        aria-describedby="name-error"
                                    />
                                    {isClicked && !isValidate.image_title && (
                                        <small id="name-error" className='text-danger my-0 d-block fz-12px mt-3 fw-100'>Please fill this field</small>
                                    )}
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup>
                                    <label for="blogThumbnail">Blog Image</label>
                                    <Input type="file" id="blogThumbnail" name="blogThumbnail" onChange={(e) => handleInputChange(e.target.name, e.target.files[0])}
                                        onBlur={() => setIsValidate({ ...isValidate, blogThumbnail: true })}
                                        required
                                        aria-describedby="name-error" />

                                    <FormText color="muted">
                                        Please select .png or.jpg images only
                                    </FormText>
                                    {isClicked && !isValidate.blogThumbnail && (
                                        <small id="name-error" className='text-danger my-0 d-block fz-12px mt-3 fw-100'>Please fill this field</small>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
        <label>Description Type:</label>
          <Col lg="12">
            <div>
              <CustomInput
                type="radio"
                id="text"
                label="External Link"
                value="text"
                checked={blogType === 'text'}
                onChange={() => handleBlogContent('text')}
              />
              <CustomInput
                type="radio"
                id="editor"
                label="Blog Description"
                value="editor"
                checked={blogType === 'editor'}
                onChange={() => handleBlogContent('editor')}
              />
              {/* <label htmlFor="text">Text</label> */}
              </div>
          </Col>
          {blogType === 'text' && (
            <Col lg="12">
              <FormGroup>
                <label htmlFor="blogContent">Exteranl Link</label>
                <Input
                  type="textarea"
                  id="blogContent"
                  placeholder="Enter text content"
                  name="external_link"
                  onChange={(e) => handleInputChange('external_link', e.target.value)}
                />
              </FormGroup>
            </Col>
          )}
          {/* {blogType === 'editor' && (
            
            <Col lg="12">
            
            <FormGroup>
                <label htmlFor="blogContent">Title</label>
                <Input
                type="text"
                id="blogContent"
                placeholder="Enter title"
                name="bb_story_title"
                onChange={(e) => handleStoryInputChange('0','bb_story_title', e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <label htmlFor="blogContent">Editor Content</label>
                <Editor 
                onTextChange={(e) => handleStoryInputChange('0','bb_story_description', e.htmlValue)} 
                style={{ height: '100px' }} 
                name="bb_story_description" 
                />
            </FormGroup>
            </Col>

          )} */}
          {/* {blogType === 'editor' && ( */}
           {blogType === 'editor'  && stateFormData.blog_list?.map((type, index) => (
            <Col lg="12" key={index}>
              <FormGroup>
              <FormGroup>
                <label htmlFor="blogContent">Title</label>
                <Input
                  type="text"
                  id="blogContent"
                  placeholder="Enter title"
                  name="bb_story_title"
                  onChange={(e) => handleStoryInputChange(index,'bb_story_title', e.target.value)}
                />
              </FormGroup>
              <Editor onTextChange={(e) => handleStoryInputChange(index,'bb_story_description', e.htmlValue)} style={{ height: '100px' }} name="bb_story_description" />
              </FormGroup>
            </Col>
          ))}
          {/* )} */}
         {blogType === 'editor' && (
            <Col lg="12">
              <Button color="primary" onClick={handleAddNewStoryPoint} className="mt-3">
                + Add More
              </Button>
            </Col>
          )}
        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleCreate}>
                            Add
                        </Button>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* Success Modal */}
                <Modal isOpen={successModal} toggle={toggleSuccessModal} centered>
                    <ModalBody>
                        <Alert color="success">Blog added successfully!</Alert>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={toggleSuccessModal}>
                            OK
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* Edit Modal */}
                <Modal isOpen={isEditModalOpen} toggle={closeEditModal} centered size="lg">
                    <ModalHeader toggle={closeEditModal} tag={modalHeaderTag}>Edit Row</ModalHeader>
                    <ModalBody className="bg-secondary">
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="blogName"
                                    >
                                        Blog Name
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        id="blogName"
                                        placeholder="please enter name"
                                        type="text"
                                        name="blogName"
                                        defaultValue={selectedRow.blogName}
                                        
                                        onChange={(e) => handleEditInputChange (e.target.name,e.target.value)
                                        }   
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="blogSubject"
                                    >
                                        Blog subject
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        name="blogSubject"
                                        id="blogSubject"
                                        placeholder="please enter subject "
                                        type="text"
                                        defaultValue={selectedRow.blogSubject||""}
                                        onChange={(e)=>
                                            handleEditInputChange (e.target.name,e.target.value)
                                        }
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="blogContent"
                                    >
                                        Blog content
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        id="blogContent"
                                        placeholder="please enter content"
                                        type="text"
                                        name="blogContent"
                                        defaultValue={selectedRow.blogContent||""}
                                        onChange={(e)=>
                                            handleEditInputChange (e.target.name,e.target.value)
                                        }
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="blog_type"
                                    >
                                        Blog type
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        defaultValue={selectedRow.blog_type||""}
                                        id="blog_type"
                                        name="blog_type"
                                        placeholder="please enter type"
                                        type="text"
                                        onChange={(e)=>
                                            handleEditInputChange (e.target.name,e.target.value)
                                        }
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="blogDescription"
                                    >
                                        Blog description
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        defaultValue={selectedRow.blogDescription||""}
                                        id="blogDescription"
                                        placeholder="please enter description"
                                        name="blogDescription"
                                        type="text"
                                        onChange={(e)=>
                                            handleEditInputChange (e.target.name,e.target.value)
                                        }
                                    />
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="image_title"
                                    >
                                        Image Title
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        defaultValue={selectedRow.image_title||""}
                                        id="image_title"
                                        name="image_title"
                                        placeholder=""
                                        type="text"
                                        onChange={(e)=>
                                            handleEditInputChange (e.target.name,e.target.value)
                                        }
                                    />
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup>
                                    <label for="blogThumbnail">Blog Image</label>
                                    <Input type="file" 
                                    id="blogThumbnail" 
                                    name="blogThumbnail" 
                                    onChange={(e) => handleEditInputChange (e.target.name, e.target.files[0])} 
                                    onBlur={() => setIsValidate({ ...isValidate, blogThumbnail: true })}
                                    required
                                    aria-describedby="name-error"/>
                                     
                                    <FormText color="muted">
                                        Please select .png or.jpg images only
                                    </FormText>
                                    {isImageVisible && (updatedBlogThumbnail|| selectedRow.leader_image) ? (
                                        <div style={{ position: 'relative', display: 'inline-block' }}>
                                            <MyS3Component key={selectedRow?.blogCode} imageUrl={selectedRow?.blogThumbnail?selectedRow?.blogThumbnail :updatedBlogThumbnail} />
                                            <Button size="sm" style={{ position: 'absolute', top: 0, right: "-40px" }} onClick={handleRemoveImage}>
                                                X
                                            </Button>
                                        </div>
                                    ) : null}
                                    {isClicked && !isValidate.blogThumbnail && !stateFormData.blogThumbnail && (
                                        <small className='text-danger my-0 d-block fz-12px mt-3 fw-500'>Please Fill this field</small>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="link"
                                    >
                                        link
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        name="link"
                                        defaultValue={selectedRow.link|| ""}
                                        id="link"
                                        placeholder=""
                                        type="textarea"
                                    />
                                </FormGroup>
                            </Col>
                            <label>Description Type:</label>
          <Col lg="12">
            <div>
              <CustomInput
                type="radio"
                id="text"
                label="External Link"
                value="text"
                checked={blogType === 'text'}
                onChange={() => handleBlogContent('text')}
              />
              <CustomInput
                type="radio"
                id="editor"
                label="Blog Description"
                value="editor"
                checked={blogType === 'editor'}
                onChange={() => handleBlogContent('editor')}
              />
              {/* <label htmlFor="text">Text</label> */}
              </div>
          </Col>
          {blogType === 'text' && (
            <Col lg="12">
              <FormGroup>
                <label htmlFor="blogContent">External Link</label>
                <Input
                  type="textarea"
                  id="blogContent"
                  placeholder="Enter text content"
                  name="external_link"
                  Value={selectedRow.external_link }
                  onChange={(e) => handleEditStoryInputChange('external_link', e.target.value)}
                  
                />
              </FormGroup>
            </Col>
          )}
          {/* {blogType === 'editor' && (
            
            <Col lg="12">
            
            <FormGroup>
                <label htmlFor="blogContent">Title</label>
                <Input
                type="text"
                id="blogContent"
                placeholder="Enter title"
                name="bb_story_title"
                onChange={(e) => handleEditStoryInputChange('0','bb_story_title', e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <label htmlFor="blogContent">Editor Content</label>
                <Editor 
                onTextChange={(e) => handleEditStoryInputChange('0','bb_story_description', e.htmlValue)} 
                style={{ height: '100px' }} 
                name="bb_story_description" 
                />
            </FormGroup>
            </Col>

          )} */}
           {blogType === 'editor' && selectedRow.blog_list?.map((type, index) => (
            <Col lg="12" key={index}>
              <FormGroup>
              <FormGroup>
                <label htmlFor="blogContent">Title</label>
                <Input
                  type="text"
                  id="blogContent"
                  placeholder="Enter title"
                  name="bb_story_title"
                  value={type.bb_story_title}
                  onChange={(e) => handleEditStoryInputChange(index,'bb_story_title', e.target.value)}
                />
              </FormGroup>
              <Editor onTextChange={(e) => handleEditStoryInputChange(index,'bb_story_description', e.htmlValue)} style={{ height: '100px' }} name="bb_story_description" value={type.bb_story_description} />
              </FormGroup>
            </Col>
          ))}
         {blogType === 'editor' && (
            <Col lg="12">
              <Button color="primary" onClick={handleEditNewStoryPoint} className="mt-3">
                + Add More
              </Button>
            </Col>
          )}
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleEdit}>
                            Save
                        </Button>{" "}
                        <Button color="secondary" onClick={closeEditModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* Delete Modal */}
                <Modal isOpen={isDeleteModalOpen} toggle={closeDeleteModal} centered size="lg">
                <ModalHeader toggle={closeDeleteModal} tag={modalHeaderTag}>Delete</ModalHeader>
                <ModalBody className="bg-secondary">
                    Are you sure you want to delete this Blog?
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={handleDelete}>
                        Yes
                    </Button>{" "}
                    <Button color="secondary" onClick={closeDeleteModal}>
                        No
                    </Button>
                </ModalFooter>
            </Modal>
            </Container>
        </>
    );
};

export default Blog;