import { useState, useEffect } from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    CustomInput,
    UncontrolledTooltip,
    Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Table, Alert,FormText
} from "reactstrap";
import ReactPaginate from 'react-js-pagination';
import axiosInstance from '../../axiosInterceptor';
import MyS3Component from "../../components/s3/s3";
import Swal from "sweetalert2";
// core components
// import Header from "components/Headers/Header.js";
//import Table from "../../components/Table/Table";
 
{/*const data = [
    { id: 1, icons: 'John Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 1', action: 'edit,delete', status: 'active' },
    { id: 2, icons: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 2', action: 'edit,delete', status: 'active' },
    { id: 3, icons: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 3', action: 'edit,delete', status: 'active' },
    { id: 4, icons: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 4', action: 'edit,delete', status: 'active' },
    { id: 5, icons: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 5', action: 'edit,delete', status: 'active' },
    { id: 6, icons: 'John Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 6', action: 'edit,delete', status: 'active' },
    { id: 7, icons: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 7', action: 'edit,delete', status: 'active' },
    { id: 8, icons: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 8', action: 'edit,delete', status: 'active' },
    { id: 9, icons: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 9', action: 'edit,delete', status: 'active' },
    { id: 10, icons: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 10', action: 'edit,delete', status: 'active' },
    // Add more data as needed
];
*/}
 
const columns = ['id', 'Award NAME', 'award_image', 'award_description', 'award_link','action',];
const Award = () => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const itemsPerPage = 5; // Number of items to display per page
    const [currentPage, setCurrentPage] = useState(1);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    console.log("statedata",selectedRow);
        const [successModal, setSuccessModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    // const [formData, setFormData] = useState({});
    const [AwardData, SetAwardData] = useState([]);
    const toggleSuccessModal = () => setSuccessModal(!successModal);
    const [stateFormData, setFormData] = useState({});
    const [tableData, setTableData] = useState([]);
    const [selectedAwardData,setSelectedAwardData]= useState({})
    const [isImageVisible, setIsImageVisible] = useState(true);

    const [updatedAwardImage,setUpdatedAwardImage]=useState({})
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        const indexOfLastItem = pageNumber * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = AwardData?.slice(indexOfFirstItem, indexOfLastItem);
        setTableData(currentItems);
    };
    const [isClicked, setIsClicked] = useState(false);
    const [isValidate, setIsValidate] = useState({})
        useEffect(() => {
        AwardGetApi()
        console.log("AwardData", AwardData)
    }, []);
    const token = localStorage.getItem('JWT');
    const handleRemoveImage = () => {
        // Handle the logic to remove the image
        setIsImageVisible(false);
    };

    const AwardGetApi = () => axiosInstance.get("award/awardRetrieve").then(response => {
        console.log("Award response DATA", response.data);
        SetAwardData(response.data.data);
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = response.data.data?.slice(indexOfFirstItem, indexOfLastItem);
        setTableData(currentItems);
 
    }).catch(error => {
        console.log("Error from API", error);
    })
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = (AwardData && AwardData.data) ? AwardData.data.slice(indexOfFirstItem, indexOfLastItem) : [];
    //console.log("data", data, columns);
    const modalHeaderTag = 'h2';
    const openEditModal = async (row) => {
        setSelectedRow(row);
        setEditModalOpen(true);

        // Fetch service data by code
        try {
            const response = await axiosInstance.get(`/award/awardRetrieve?awardcode=${row.awardcode}`);
            setSelectedAwardData(response.data.data[0]);
        } catch (error) {
            console.log("Error fetching service data by code:", error);
        }
    };
    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        setSelectedImage(imageFile);
    };
    const openDeleteModal = (row) => {
        setSelectedRow(row);
        setDeleteModalOpen(true);
    };
    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    
        if (file && !allowedTypes.includes(file.type)) {
            alert('Please select a valid image file (PNG or JPG).');
            e.target.value = '';
        } else {
            handleInputChange(e.target.name, file);
        }
    };
    const closeEditModal = () => {
        setEditModalOpen(false);
    };
 
    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
    };
    const handleCreate = async () => {
        setIsClicked(true);
    
        // Validation checks
        const validationErrors = {};
        if (stateFormData.award_name?.length < 5) {
            validationErrors.award_name = "Input must be at least 5 characters long";
        }
        if (!stateFormData.award_name) {
            validationErrors.award_name = 'Please fill this field';
        }
        if (stateFormData.award_description?.length < 5) {
            validationErrors.award_description = "Input must be at least 5 characters long";
        }
        if (!stateFormData.award_description) {
            validationErrors.award_description = 'Please fill this field';
        }
        if (!stateFormData.award_link) {
            validationErrors.award_link = 'Please fill this field';
        }
        if (!stateFormData.award_image) {
            validationErrors.award_image = 'Please fill this field';
        }
        setIsValidate(validationErrors);
        if (Object.keys(validationErrors)?.length > 0) {
            return;
        }
    
        // If no validation errors, continue with API call
        const config = {
            headers: {
                "content-type": "multipart/form-data",
                "x-auth-token": token
            }
        };
        const api = "/Award/AwardCreation";
        try {
            const response = await axiosInstance.post(api, stateFormData, config);
            console.log("Award creation response DATA", response.data);
            AwardGetApi();
            toggle();
            setFormData({});
            // Display success popup
            // Swal.fire({
            //     icon: 'success',
            //     title: 'Success',
            //     text: 'Award added successfully!',
            //     confirmButtonColor: '#3085d6',
            //     confirmButtonText: 'OK'
            // });
        } catch(error) {
            console.log("Award creation Error from API", error);
            
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Error',
            //     text: 'Award is not created.',
            //     confirmButtonColor: '#d33',
            //     confirmButtonText: 'OK'
            // });
        }
    };
    
    
        // Implement your edit logic here
        const handleEdit = async () => {
            setIsClicked(true);
        const validationErrors = {};

        const validationRules = {
            award_name: { required: true, minLength: 5 },
            award_description: { required: true, minLength: 5 },
            award_link: { required: true, minLength: 5 },
            award_image: { required: true }
        };
        
        for (const [fieldName, rules] of Object.entries(validationRules)) {
            const value = stateFormData[fieldName] || selectedRow[fieldName];
            if (rules.required && (!value || value === "")) {
                validationErrors[fieldName] = 'Please fill this field';
            } else if (rules.minLength && value && value.length < rules.minLength) {
                validationErrors[fieldName] = `Please fill this field atleast ${rules.minLength} characters long`;
            }
        }

        setIsValidate(validationErrors);

        // Check if there are any validation errors
        if (Object.keys(validationErrors).length > 0) {
            return; // Stop further execution if there are validation errors
        }
            const formData = new FormData();
            formData.append("awardcode", selectedRow.awardcode);
            formData.append("award_name", stateFormData.award_name|| selectedRow.award_name);
            formData.append("award_image", stateFormData.award_image|| "");
            formData.append("award_description", stateFormData.award_description || selectedRow.award_description);
            formData.append("award_link", stateFormData.award_link || selectedRow.award_link);
    
            try {
                const response = await axiosInstance.put("/award/awardEdit", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "x-auth-token": token
                    }
                });
                const updatedAwardImage = response.data.award_image;
            setUpdatedAwardImage(updatedAwardImage);
    
                console.log("Edit award response:", response.data);
                AwardGetApi();
                closeEditModal();
                // Swal.fire({
                //     icon: 'success',
                //     title: 'Success',
                //     text: 'Award Updated successfully!',
                //     confirmButtonColor: '#3085d6',
                //     confirmButtonText: 'OK'
                // });
            } catch (error) {
                console.error("Error updating award:", error);
                // Swal.fire({
                //     icon: 'error',
                //     title: 'Error',
                //     text: 'Failed to update Award',
                //     confirmButtonColor: '#d33',
                //     confirmButtonText: 'OK'
                // });
            }
        };
        
 
        const handleDelete = async () => {
            try {
                const response = await axiosInstance.delete(`/award/awardsDelete?awardcode=${selectedRow.awardcode}`,{
                    headers:{
                        'x-auth-token': token
                    }
                }
                );
                console.log("Delete clients response:", response.data);
                AwardGetApi();
                closeDeleteModal();
                // Swal.fire({
                //     icon: 'success',
                //     title: 'Success',
                //     text: 'Awards deleted successfully!',
                //     confirmButtonColor: '#3085d6',
                //     confirmButtonText: 'OK'
                // });
            } catch (error) {
                console.log("Error deleting clients:", error);
                // Swal.fire({
                //     icon: 'error',
                //     title: 'Error',
                //     text: 'Awards is not Deleted',
                //     confirmButtonColor: '#d33',
                //     confirmButtonText: 'OK'
                // });
            }
        };
    const handleInputChange = (inputName, val) => {
        console.log("Event data change", inputName, "   val", val);
        if(inputName ==='award_image'){
            const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            if (val && !allowedTypes.includes(val.type)) {
                alert('Please select a valid image file (PNG or JPG).');
            }
            setFormData((prevData) => {
                return {
                    ...prevData,
                    [inputName]: val,
                }
            })
            setIsValidate((prevValidation) => ({
                ...prevValidation,
                [inputName]: !!val,
            }));

        }
        setFormData((prevData) => {
            return {
                ...prevData,
                [inputName]: val,
            }
        })
        setIsValidate((prevValidation) => ({
            ...prevValidation,
            [inputName]: !!val, // Update validation status based on whether the field has a value
        }));
    };
    return (
        <>
            <Container className="mt-5" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="bg-transparent d-flex justify-content-between">
                                <h2 className="mb-0">Awards</h2>
                                <Button color="primary" onClick={toggle}>Add</Button>
                            </CardHeader>
                            <CardBody>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            {columns.map((column, index) => (
                                                <th key={index}>{column}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.map((row, rowIndex) => (
                                            <tr key={rowIndex}>
                                                <td>{rowIndex + 1}</td>
                                                <td>{row['award_name']}</td>
                                                <td><MyS3Component key={row['awardcode']} imageUrl={row['award_image']} /></td>
                                                <td>{row['award_description']}</td>
                                                <td>{row['award_link']}</td>
 
                                                <td>
 
                                                    <button className="btn btn-primary" onClick={() => openEditModal(row)}>
                                                        edit
                                                    </button>
                                                    <button className="btn btn-default" onClick={() => openDeleteModal(row)}>
                                                        delete
                                                    </button>
                                                </td>
 
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                {
                                    AwardData.length > itemsPerPage && (
                                        <ReactPaginate
                                            activePage={currentPage}
                                            itemsCountPerPage={itemsPerPage}
                                            totalItemsCount={AwardData.length}
                                            pageRangeDisplayed={5}
                                            onChange={handlePageChange}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            innerClass="pagination justify-content-center"
                                        />
                                    )
                                }
                            </CardBody>
                        </Card>
                    </div>
                </Row>
                <Modal isOpen={modal} toggle={toggle} centered size="lg">
                    <ModalHeader toggle={toggle} tag={modalHeaderTag}>Add Award</ModalHeader>
                    <ModalBody className="bg-secondary">
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="award_name"
                                    >
                                AWARD NAME
                                    </label>
                                    <Input
                                         className={`form-control-alternative ${
                                            isValidate.award_name ? (isValidate.award_name?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        defaultValue=""
                                        id="award_name"
                                        placeholder="Please Enter name"
                                        type="text"
                                        onBlur={() => setIsValidate({ ...isValidate,award_name : true })}
                                        required
                                        aria-describedby="name-error"
                                        name="award_name"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.target.value === '' && !isNaN(parseInt(e.key))) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    {isClicked && isValidate.award_name && isValidate.award_name?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.award_name}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Description"
                                    >
                                        Award Description
                                    </label>
                                    <Input
                                         className={`form-control-alternative ${
                                            isValidate.award_description ? (isValidate.award_description?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        defaultValue=""
                                        id="Description"
                                        placeholder="Please Enter type"
                                        type="text"
                                        onBlur={() => setIsValidate({ ...isValidate, award_description: true })}
                                        required
                                        aria-describedby="name-error"
                                        name="award_description"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                    />
                                     {isClicked && isValidate.award_description && isValidate.award_description?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.award_description}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Description"
                                    >
                                    Award Link
                                    </label>
                                    <Input
                                        className={`form-control-alternative ${
                                            isValidate.award_link ? (isValidate.award_link?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        defaultValue=""
                                        id="subject"
                                        rows={6}
                                        placeholder="Please Enter Link"
                                        type="textarea"
                                        onBlur={() => setIsValidate({ ...isValidate, award_link: true })}
                                        required
                                        aria-describedby="name-error"
                                        name="award_link"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        
                                    />
                                     {isClicked && isValidate.award_link && isValidate.award_link?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.award_link}</small>
                                )}
                                </FormGroup>
                            </Col>
                          
 
                            <Col lg="6">
                                <FormGroup>
                                    <label for="award_image">Award Image</label>
                                <Input type="file" id="award_image" name="award_image" onBlur={() => setIsValidate({ ...isValidate,award_image: true })} required aria-describedby="name-error" onChange={(e) => handleInputChange(e.target.name, e.target.files[0])}
                                    accept=".png, .jpg, .jpeg" />
                                    <FormText color="muted">
                                    Please select .png or .jpg images only within 1024x768px.
                                    </FormText>
                                    {isClicked && isValidate.award_image && isValidate.award_image?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.award_image}</small>
                                )}
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleCreate}>
                            Add
                        </Button>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={successModal} toggle={toggleSuccessModal} centered>
                    <ModalBody>
                        <Alert color="success">Awards added successfully!</Alert>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={toggleSuccessModal}>
                            OK
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* Edit Modal */}
                <Modal isOpen={isEditModalOpen} toggle={closeEditModal} centered size="lg">
                    <ModalHeader toggle={closeEditModal} tag={modalHeaderTag}>Edit Row</ModalHeader>
                    <ModalBody className="bg-secondary">
                        <Row>
                        <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="award_name"
                                    >
                                AWARD NAME
                                    </label>
                                    <Input
                                        className={`form-control-alternative ${
                                            isValidate.award_name ? (isValidate.award_name?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        defaultValue={selectedAwardData.award_name || ""}
                                        id="award_name"
                                        placeholder="Please Enter name"
                                        type="text"
                                        name="award_name"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                    />
                                    {isClicked && isValidate.award_name && isValidate.award_name?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.award_name}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Description"
                                    >
                                        Award Description
                                    </label>
                                    <Input
                                         className={`form-control-alternative ${
                                            isValidate.award_description ? (isValidate.award_description?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        defaultValue={selectedAwardData.award_description || ""}
                                        id="Description"
                                        placeholder="Please Enter type"
                                        type="text"
                                        name="award_description"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                    />
                                     {isClicked && isValidate.award_description && isValidate.award_description?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.award_description}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Description"
                                    >
                                    Award Link
                                    </label>
                                    <Input
                                         className={`form-control-alternative ${
                                            isValidate.award_link ? (isValidate.award_link?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        defaultValue={selectedAwardData.award_link || ""}
                                        id="subject"
                                        rows={6}
                                        placeholder="Please Enter Link"
                                        type="textarea"
                                       
                                        name="award_link"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                    />
                                     {isClicked && isValidate.award_link && isValidate.award_link?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.award_link}</small>
                                )}
                                </FormGroup>
                            </Col>
                          
 
                            <Col lg="6">
                                <FormGroup>
                                    <label for="award_image">Award Image</label>
                                <Input type="file" 
                                id="award_image" 
                                name="award_image" 
                                onBlur={() => setIsValidate({ ...isValidate,award_image: true })} 
                                onChange={(e) => handleFileInputChange(e)}
                                accept=".png, .jpg, .jpeg" 
                                 required 
                                 aria-describedby="name-error" />
                                    <FormText color="muted">
                                    Please select .png or .jpg images only within 1024x768px.
                                    </FormText>
                                    {isImageVisible && (updatedAwardImage || selectedAwardData.award_image) ? (
                                        <div style={{ position: 'relative', display: 'inline-block' }}>
                                            <MyS3Component key={selectedAwardData?.awardcode} imageUrl={selectedAwardData?.award_image ? selectedAwardData?. award_image : updatedAwardImage} />
                                            <Button size="sm" style={{ position: 'absolute', top: 0, right: "-40px" }} onClick={handleRemoveImage}>
                                                X
                                            </Button>
                                        </div>
                                    ) : null}
                                     {isClicked && isValidate.award_image && isValidate.award_image?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.award_image}</small>
                                )}
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleEdit}>
                            Save
                        </Button>{" "}
                        <Button color="secondary" onClick={closeEditModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* Delete Modal */}
                <Modal isOpen={isDeleteModalOpen} toggle={closeDeleteModal} centered size="lg">
                <ModalHeader toggle={closeDeleteModal} tag={modalHeaderTag}>Delete</ModalHeader>
                <ModalBody className="bg-secondary">
                    Are you sure you want to delete this Award?
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={handleDelete}>
                        Yes
                    </Button>{" "}
                    <Button color="secondary" onClick={closeDeleteModal}>
                        No
                    </Button>
                </ModalFooter>
            </Modal>
            </Container>
        </>
    );
};
 
export default Award;